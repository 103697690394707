import { useState, useEffect, useContext } from "react";
import { MobXProviderContext } from "mobx-react";

export function useHandleUserVariables() {
  const { GlobalStore } = useContext(MobXProviderContext);
  const { userType } = GlobalStore;

  //new users are lower in hierarchy as greater numbers
  const handleNewUserType = () => userType + 1;

  const {
    user: { uid },
    countryId,
    compId,
  } = GlobalStore;

  switch (userType) {
    case 0:
      return {
        uid,
        type: handleNewUserType(),
      };
    case 1:
      return {
        uid,
        type: handleNewUserType(),
        pais: countryId,
      };
    case 2:
      return {
        uid,
        type: handleNewUserType(),
        pais: countryId,
        empresa: compId,
      };
    default:
      return null;
  }
}

export function useGetTableData(ref, id) {
  const { GlobalStore } = useContext(MobXProviderContext);
  const { db } = GlobalStore.firebase;
  const collectionRef = db.collection(ref);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const dataQuery = async () => {
      try {
        const query = await collectionRef
          .where("configuracion", "==", db.doc(`configuraciones/${id}`))
          .orderBy("row", "asc")
          .get();
        const arr = [];
        query.forEach((doc) => {
          arr.push({ ...doc.data(), id: doc.id });
        });
        setData(arr);
        // console.log('Hooks - useGetTableData: ', arr);
      } catch (error) {
          console.error(error);
        throw error;
      }
      setLoading(false);
    };
    dataQuery();
    return () => dataQuery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  return [loading, data, setData, setLoading];
}
