////@ts-check

import React, { useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from 'react-i18next';

import HandleLabel from "./HandleLabel";
import Error from "./Error";


export default function SelectGroup({ field, required, disabled }) {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { i18n, t } = useTranslation();
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname, options } = field;
    const { handleChange, handleBlur, values, errors, isValid } = formik;

    const traduce = (f) => {
        return (i18n.exists('domains:' + fieldname + '.'+ f))
            ? t('domains:' + fieldname + '.'+ f)
            : f;
    };

    const handleOptions = (/** @type {any} */ options) => {

        const result = options.reduce((r, o) => {
            r[o.group] || (r[o.group] = []);

            //TODO: Eliminar testeo de diccionario
            // ========================== Para TESTEO ==========================
            // @ts-ignore
            // window.sacarEntradaDiccionario(fieldname, o.fieldValue, o.fieldText, 'helpers/FieldBuilder/SelectGroupsOptions');
            // ========================== Para TESTEO ==========================

            r[o.group].push({
                label: traduce(o.fieldValue),
                value: o.fieldValue,
                unit: o.fieldUnit
            });
            return r;
        }, {});


        const rr =  _.map(result, (v, k) => {

            //TODO: Eliminar testeo de diccionario
            // ========================== Para TESTEO ==========================
            // @ts-ignore
            window.sacarEntradaDiccionario(fieldname, 'GROUP', k, 'helpers/FieldBuilder/SelectGroupsOptions');
            // ========================== Para TESTEO ==========================

            return { items: v, label: t('domains:' + fieldname + '.' + k) };
        });
        return rr;
    };

    const itemTemplate = (/** @type {any} */ option) => {
        return  option && (<div className="templateOptionSelect">
                    {option.label}
                    {option.unit && (<small>{i18n.exists('domains:units.'+option.unit)?t('domains:units.'+option.unit):option.unit}</small>)}
                </div>) || ('<div>&nbsp;</div>');
    };

    const groupedItemTemplate = (/** @type {any} */ option) => {
        return  (<div>
                    <b style={{ fontSize: '110%' }}> {option.label} </b>
                </div>);
    };

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <Dropdown
                id={fieldname}
                inputId={idInput}
                name={fieldname}
                options={handleOptions(options)}
                itemTemplate={itemTemplate}
                valueTemplate={itemTemplate}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[fieldname]}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupedItemTemplate}
                disabled={disabled}
            />
            <Error isValid={isValid} errors={errors[fieldname]} />
        </div>
    );
}
