//@ts-check

import React, { useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { MobXProviderContext } from 'mobx-react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';


import { FORM_DUPLICATE } from '../../../queries/queries_forms';
import * as ROUTES from '../../../constants/routes';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

import './duplicate.scss';


export default function Duplicate() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { form, id } = useParams();

    const [duplicateForm, { loading, error }] = useMutation(FORM_DUPLICATE, {
        variables: {
            id,
            uid: GlobalStore.user.uid,
        },
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    return (
        <div className="page-duplicate-form">
            <h1> {t('crud.duplicate_form')} </h1>
            <Message severity="warn" text={t('crud.please_confirm')}></Message>
            <p>{t('crud.information_will_be_duplicated')}</p>
            <div className="actions">
                <Button
                    type="button"
                    label={t('common:cancel')}
                    className="p-button-primary"
                    onClick={() => navigate(`${ROUTES.READ_FORMS}/${form}`)}
                />
                <Button
                    type="button"
                    label={t('common:duplicate')}
                    onClick={async () => {
                        await duplicateForm();
                        navigate(`${ROUTES.READ_FORMS}/${form}`);
                    }}
                />
            </div>
        </div>
    );
}
