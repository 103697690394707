//@ ts-check

import React from 'react';
import { useTranslation } from 'react-i18next';

import logo_es from '../../images/logo-es.svg';
import logo_en from '../../images/logo-en.svg';

import './header.scss';

import { TopMenu } from '../Menus';

export default function Header() {

    const { i18n } = useTranslation();
    const logo = (i18n.language.split('-')[0] === 'es') ? logo_es : logo_en;

    return (
        <header>
            <img src={logo} alt="Logo" className="logo" />
            <TopMenu />
        </header>
    );
}
