import React, {useState} from 'react';
// import _ from 'lodash';
import ChartDoughnutTabla1Wrapper from './ChartDoughnutTabla1Wrapper';

import './graficas.scss';

export default function ChartDoughnutTabla1Contenedor({data}) {
    const [graficas, setGraficas] = useState(['_GraficaFija_'+Math.round(Math.random() * 10000000000)]);

    const addChart = () => {
        const idChart = '_' + Math.round(Math.random() * 10000000000);
        setGraficas(oGr => [...oGr, idChart]);
    };

    const delChart = (id) => {
        setGraficas(oGr => oGr.filter(o => o !== id));
    };

    //defGraficas(graficas);
    if (data.values && data.values.length === 0) {
        return '';
    }


    return (
        <div className="ContenedorDeGraficas">
            { graficas.map(
                oGr => (oGr===graficas[0])
                    ? <ChartDoughnutTabla1Wrapper data={{...data}} addChart={addChart} idChart={oGr} key={oGr}/>
                    : <ChartDoughnutTabla1Wrapper data={{...data}} delChart={delChart} idChart={oGr} key={oGr}/>
            )}
        </div>
    );
}
