const config = {
  apiKey: "AIzaSyAlDKhVVu01sTz0G3CKriTioYh7n3Ny_MM",
  authDomain: "fao-wbfcwf.firebaseapp.com",
  databaseURL: "https://fao-wbfcwf-default-rtdb.firebaseio.com",
  projectId: "fao-wbfcwf",
  storageBucket: "fao-wbfcwf.appspot.com",
  messagingSenderId: "787257267410",
  appId: "1:787257267410:web:010ca14028e4e9e650090b",
  measurementId: "G-PJW21DH4VK",
};

export default config;
