import React, { useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import { InputText } from "primereact/inputtext";

import HandleLabel from "./HandleLabel";
import Error from "./Error";

export default function Text({ field, required, disabled }) {
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname } = field;
    const { handleChange, handleBlur, values, errors, isValid } = formik;

    const handleKey = (e) => {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <InputText
                type="text"
                name={fieldname}
                id={fieldname}
                disabled={disabled}
                onChange={handleChange}
                onKeyDown={handleKey}
                onBlur={handleBlur}
                value={values[fieldname]}
            />
            <Error isValid={isValid} errors={errors[fieldname]} />
        </div>
    );
}
