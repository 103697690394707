//@ts-check

function galones2litros(g) {
    return g*3.785;
}

function cGalones2litros(c) {
    return c*0.946;
}

/**
 *
 * @param {string} tipo
 * @param {number} num
 * @returns number
 */
export function convert2Liters(tipo, num) {
    switch (tipo) {
        case 'litros': return num;
        case 'galones': return galones2litros(num);
        case 'cuartos_de_galon': return cGalones2litros(num);
        default: return null;
    }
}
