import { gql } from '@apollo/client';

const CONFIG_GLOBAL = gql`
  query ConfigList($pais: ID!) {
    configuracionGlobal(pais: $pais) {
      id
      pais {
        nombrePais
      }
      containEmptyValues
      publicado
    }
  }
`;

const CONFIG_ADD = gql`
  mutation ConfigAdd($uid: ID!, $anio: Int!, $pais: ID!, $empresa: ID!) {
    configuracionAgregar(
      uid: $uid
      anio: $anio
      pais: $pais
      empresa: $empresa
    ) {
      id
      anio
    }
  }
`;

const CONFIG_CLONE = gql`
  mutation ConfigClone($id: ID!, $uid: ID!, $anio: Int!, $empresa: ID!) {
    configuracionDuplicar(id: $id, uid: $uid, anio: $anio, empresa: $empresa) {
      id
      anio
    }
  }
`;

const CONFIG_ACTIVATE = gql`
  mutation ConfigActivate($id: ID, $uid: ID!, $configuracion: ID) {
    empresaAsignarConfiguracion(
      id: $id
      uid: $uid
      configuracion: $configuracion
    ) {
      id
    }
  }
`;

const CONF_CURRENT = gql`
  query GetCurrentConf($uid: ID!) {
    configuraciones(uid: $uid) {
      anio
    }
  }
`;

const CONFIG_LIST = gql`
  query ConfigList($uid: ID!) {
    configuraciones(uid: $uid) {
      id
      anio
      containEmptyValues
      configuracionActiva
      publicado
    }
  }
`;

export {
  CONFIG_GLOBAL,
  CONFIG_CLONE,
  CONFIG_ACTIVATE,
  CONFIG_ADD,
  CONF_CURRENT,
  CONFIG_LIST
};
