// //@ts-check


import React, { useEffect, useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import _ from 'lodash';
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { Helmet } from 'react-helmet-async';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../../constants/routes';
import { FORM_GET, FORM_EDIT, FORM_LIST } from '../../../../queries/queries_forms';
import FieldBuilder from '../../../../helpers/FieldBuilder';
import CalcFieldBuilder from '../../../../helpers/CalcFieldBuilder';
import validationSchema from '../../../../helpers/validationSchema';
import mapParamsWithForm from '../../mapParams';
import { CompLoading } from '../../../Loaders';
import Err from '../../../Err';


export default function EditForm() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id, form } = useParams();
    const { uid } = GlobalStore.user;

    const formData = mapParamsWithForm(form, GlobalStore);

    const { Ico, QUERY_VARIABLES, QUERY_CALC, QUERY_RETURN } = formData;

    const NAME = t('menu.' + formData.NAME);

    const { loading, error, data } = useQuery(FORM_GET, {
        fetchPolicy: 'no-cache',
        variables: {
            id,
            ...QUERY_VARIABLES,
        },
    });

    const [
        editForm,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(FORM_EDIT);

    const [
        getCalc,
        { loading: calcLoading, data: calcData, error: calcError },
    ] = useLazyQuery(QUERY_CALC, { fetchPolicy: 'no-cache' });

    useEffect(() => {
        if (data) {
            const incomingFields = JSON.parse(data.formulario.camposFormulario);
            const o = { ...incomingFields };
            if (incomingFields.fecha) {
                o.fecha = new Date(incomingFields.fecha);
            }
            if (incomingFields.fechaInicio) {
                o.fechaInicio = new Date(incomingFields.fechaInicio);
            }
            if (incomingFields.fechaFin) {
                o.fechaFin = new Date(incomingFields.fechaFin);
            }
            // console.log('useEffect: ', incomingFields, o);
            getCalc({
                variables: {
                    ...o,
                    empresa: GlobalStore.compId,
                    finca: GlobalStore.fincaId.value
                },
            });
        }
    }, [GlobalStore.compId, GlobalStore.fincaId.value, data, getCalc]);

    if (GlobalStore.userType !== 3) {
        navigate(ROUTES.DASHBOARD);
        return <span></span>;
    }

    if (!id) return <span></span>;

    if (loading) return <CompLoading />;

    if (error) return <Err error={error} />;

    //Def from GraphQL
    const def = JSON.parse(data.definicion);

    const handleValues = (values) => {
        const omitEmptyValues = _.reduce(
            values,
            (acc, value, key) => {
                if (value === '' || value === undefined) {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: value,
                };
            },
            {}
        );
        const o = { ...omitEmptyValues };
        if (values.fecha) {
            o.fecha = moment(values.fecha).format('YYYY-MM');
        }
        if (values.fechaInicio) {
            o.fechaInicio = moment(values.fechaInicio).format('YYYY-MM-DD');
        }
        if (values.fechaFin) {
            o.fechaFin = moment(values.fechaFin).format('YYYY-MM-DD');
        }
        // console.log('handleValues: ', o);
        return o;
    };

    const handleCalc = () => {
        const { values } = GlobalStore.formik;
        // console.log('handleCalc: ', values);
        return getCalc({
            variables: {
                ...handleValues(values),
                empresa: GlobalStore.compId,
                finca: GlobalStore.fincaId.value
            },
        });
    };

    const initialValues = () => {
        const o = JSON.parse(data.formulario.camposFormulario);
        // console.log('(update) initialValues: ', o);
        if (o.fecha) {
            o.fecha = moment(o.fecha)._d;
        }
        if (o.fechaInicio) {
            o.fechaInicio = moment(o.fechaInicio)._d;
        }
        if (o.fechaFin) {
            o.fechaFin = moment(o.fechaFin)._d;
        }
        return o;
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('crud.register_of_name', {NAME: NAME})}
                </title>
            </Helmet>
            <Formik
                initialValues={initialValues()}
                validationSchema={validationSchema(def)}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    await editForm({
                        variables: {
                            id,
                            uid,
                            tipoFormulario: form,
                            camposFormulario: JSON.stringify(handleValues(values))
                        },
                        refetchQueries: [
                            {
                                query: FORM_LIST,
                                variables: {
                                    uid: GlobalStore.user.uid,
                                    tipoFormulario: form,
                                }
                            }
                        ]
                    });
                    navigate(`${ROUTES.READ_FORMS}/${form}`);
                }}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="breadcrum">
                                <Link to={`${ROUTES.READ_FORMS}/${form}`}>
                                    <i className="pi pi-list" /> &nbsp; {t('crud.view_saved_records')}
                                </Link>
                            </div>
                            <div className="form-container">
                                <div>
                                    <h2> {t('crud.register_of')} {NAME}</h2>
                                    <FieldBuilder def={def} />
                                </div>
                                <div className="calc-fields">
                                    <div className="calc-container">
                                        <h3>
                                            {t('crud.calculation_of')} {NAME} <Ico />
                                        </h3>
                                        {mutationLoading && (
                                            <div className="loader">
                                                <span> {t('common:saving')} </span>
                                                <CompLoading />
                                            </div>
                                        )}
                                        <Err calcError={calcError} mutationError={mutationError} />
                                        <CalcFieldBuilder
                                            def={def}
                                            calcData={
                                                calcData ? JSON.parse(calcData[QUERY_RETURN]) : null
                                            }
                                            calcLoading={calcLoading}
                                        />
                                        <div className="buttons">
                                            <Button
                                                type="button"
                                                label={t('common:calculate')}
                                                iconPos="right"
                                                className="p-button-raised p-button-rounded"
                                                disabled={!formik.isValid}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const validate = formik.validateForm();
                                                    _.isEmpty(validate) && handleCalc();
                                                }}
                                            />
                                            <Button
                                                disabled={!formik.isValid}
                                                type="submit"
                                                className="p-button-raised p-button-rounded"
                                                icon="pi pi-check"
                                                iconPos="right"
                                                label={t('common:save')}
                                            />
                                        </div>
                                        <div className="note">
                                            <i className="pi pi-info" />
                                            {t('crud.note_calculations')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
