//@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../constants/routes';
import { CONFIG_GLOBAL } from '../../../queries/queries_config';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

export default function ListConfigGlobal() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { loading, error, data } = useQuery(CONFIG_GLOBAL, {
        fetchPolicy: 'no-cache',
        variables: {
            pais: GlobalStore.countryId,
        },
    });

    if (GlobalStore.userType !== 1) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const handleTableValue = () => {
        const { id, pais, disabled, containEmptyValues } = data.configuracionGlobal;
        return [
            {
                ...data.configuracionGlobal,
                pais: pais.nombrePais,
                disabled: disabled ? 'Desactivo' : 'Activo',
                containEmptyValues: containEmptyValues ? (
                    <i className="pi pi-times" />
                ) : (
                    <i className="pi pi-check" />
                ),
                edit: (
                    <NavLink to={`${ROUTES.UPDATE_CONFIG}/${id}`}>
                        <i className="pi pi-pencil" style={{ cursor: "pointer" }}></i>
                    </NavLink>
                ),
            },
        ];
    };

    return (
        <React.Fragment>
            <h1> {t('crud.configuration_of_calculation_factors')} </h1>
            <DataTable value={handleTableValue()} responsiveLayout="scroll">
                <Column field="pais" header={t('field.country')} />
                <Column field="containEmptyValues" header={t('field.complete_fields')} />
                <Column field="disabled" header={t('field.status')} />
                <Column field="edit" header={t('field.edit')} />
            </DataTable>
        </React.Fragment>
    );
}
