import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";


import UserAdmin from "./UserAdmin";
import UserCountry from "./UserCountry";
import UserComp from "./UserComp";
import UserOp from "./UserOp";


export default function Dashboard() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { userType } = GlobalStore;

    switch (userType) {
        case 0:
            return <UserAdmin />;
        case 1:
            return <UserCountry />;
        case 2:
            return <UserComp />;
        case 3:
            return <UserOp />;
        default:
            return <UserOp />;
    }
}

