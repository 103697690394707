////@ts-check

import React from 'react';
import { useTranslation } from 'react-i18next';

import HuellaCarbonoResumen1 from './HuellaCarbonoResumen1';
import HuellaCarbonoResumen2 from './HuellaCarbonoResumen2';

export default function PaisHuellaCarbonoBloque(props) {
    const { t } = useTranslation();

    const nivelesList = [
        'fuenteEmision_claseFinca_tipoProduccion',
        'fuenteEmision_tipoProduccion_claseFinca',
        'claseFinca_fuenteEmision_tipoProduccion',
        'claseFinca_tipoProduccion_fuenteEmision',
        'tipoProduccion_claseFinca_fuenteEmision',
        'tipoProduccion_fuenteEmision_claseFinca',
    ];

    return (
        <div className="huella-carbono" mdl="src/components/CRUD/Read/Results/paisHuellaCarbonoBloque">
            <h1> {t('crud.carbon_footprint')} </h1>
            <HuellaCarbonoResumen1 requestData={props.datos} />
            <HuellaCarbonoResumen2 requestData={props.datos} nivelesList={nivelesList} formato={'categoria'}/>
        </div>
    );
}


