import React, {useState} from 'react';
// import _ from 'lodash';
import ChartColumnsTabla1Wrapper from './ChartColumnsTabla1Wrapper';

import './graficas.scss';

export default function ChartColumnsTabla1Contenedor({data}) {
    const [graficas, setGraficas] = useState(['_GraficaFijaColTab1_'+Math.round(Math.random() * 10000000000)]);

    const addChart = () => {
        const idChart = '_' + Math.round(Math.random() * 10000000000);
        setGraficas(oGr => [...oGr, idChart]);
    };

    const delChart = (id) => {
        setGraficas(oGr => oGr.filter(o => o !== id));
    };

    //defGraficas(graficas);

    return (
        <div className="ContenedorDeGraficas">
            { graficas.map(
                oGr => (oGr===graficas[0])
                    ? <ChartColumnsTabla1Wrapper data={{...data}} addChart={addChart} idChart={oGr} key={oGr}/>
                    : <ChartColumnsTabla1Wrapper data={{...data}} delChart={delChart} idChart={oGr} key={oGr}/>
            )}
        </div>
    );
}
