// @ts-nocheck

/* GoogleMaps.js */

export const getGoogleMaps = (lang) => {
    let scr;
    document.querySelectorAll('script[src^="https://maps.google.com/maps"]').forEach(script => {
        if (script.idioma === lang) {
            scr = script;
        }
    });
    return scr;
};

export const removeGoogleMaps = () => {
    document.querySelectorAll('script[src^="https://maps.google.com/maps"]').forEach(script => {
        script.remove();
        if (window.google) delete window.google.maps;
    });
};

export const loadGoogleMaps = (lang, callback) => {
    let rMap = getGoogleMaps(lang);
    if (rMap && rMap.idioma !== lang) {
        removeGoogleMaps();
    }
    if (!getGoogleMaps(lang)) {
        const script = document.createElement('script');
        const key = 'AIzaSyAlDKhVVu01sTz0G3CKriTioYh7n3Ny_MM';
        script.src = 'https://maps.google.com/maps/api/js?key=' + key + '&language=' + lang + '&callback=Function.prototype';
        script.id = 'googleMapsScript';
        script.async = true;
        script.defer = true;
        script.idioma = lang;
        script.onload = () => {
            if (callback) callback();
        };
        document.querySelector('head').appendChild(script);
    } else {
        if (callback) callback();
    }
};



/**
 * Cambia el idioma de google maps refrescando el script con el nuevo idioma
 * @param {String}   lang      - Idioma
 * @param {String}   key       - API key
 * @returns {Promise}          - Resuelve la promesa cuando finaliza la carga
 */
export const setAPILanguage = (lang, key) => {
    // Elimina la vieja api de google maps
    document.querySelectorAll('script[src^="https://maps.google.com/maps"]').forEach(script => {
        script.remove();
    });
    // @ts-ignore
    if (window.google) delete window.google.maps;

    // Genera un nuevo script de google mpas
    let newAPI = document.createElement('script');
    newAPI.src = 'https://maps.google.com/maps/api/js?key=' + key + '&language=' + lang;+ '&callback=googleMapsAPILoaded';
    newAPI.async = true;
    newAPI.defer = true;
    // @ts-ignore
    newAPI.idioma = lang;

    // Define el Callback para el src de google maps
    // @ts-ignore
    window.googleMapsAPILoaded = () => {
        let event = new CustomEvent('googleMapsAPILoaded');
        window.dispatchEvent(event);
    };

    // Crea la promesa que esperará al callback
    let apiLoaded = new Promise(resolve => {
        window.addEventListener('googleMapsAPILoaded', () => {
            resolve();
        });
    });

    // Empieza el script
    document.querySelector('head').appendChild(newAPI);

    // Devuelve la promesa
    return apiLoaded;
};

/*

let map = new google.maps.Map(document.getElementById('map'), {
  zoom: 8,
  center: new google.maps.LatLng(-34.397, 150.644)
});

document.getElementById('languageTrigger').addEventListener('click', () => {
  let lang = document.getElementById('changeLanguage').value;
  setAPILanguage(lang, ['places'], '').then(() => {
    map = new google.maps.Map(document.getElementById('map'), {
      zoom: 8,
      center: new google.maps.LatLng(-34.397, 150.644)
    });
  });
});

*/
