import React, { useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import { InputMask } from "primereact/inputmask";
import { useTranslation } from 'react-i18next';


import HandleLabel from "./HandleLabel";
import Error from "./Error";


export default function Phone({ field, required, disabled }) {
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const { fieldname } = field;
    const { handleChange, isValid, values, touched, errors } = formik;

    const handleKey = (e) => {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <InputMask
                type="tel"
                mask="+999 99999999"
                placeholder="+999-99999999"
                name={fieldname}
                id={fieldname}
                onChange={handleChange}
                onKeyDown={handleKey}
                value={values[fieldname]}
                disabled={disabled}
            />
            <small style={{ display: "block" }} >
                {t('common:phone_format')}
            </small>
            <Error
                isValid={isValid}
                touched={touched[fieldname]}
                errors={errors[fieldname]}
            />
        </div>
    );
}
