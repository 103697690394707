////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { USER_DEF, USER_ADD } from '../../../queries/queries_user';
import * as ROUTES from '../../../constants/routes';
import FieldBuilder from '../../../helpers/FieldBuilder';
import validationSchema from '../../../helpers/validationSchema';
import initialValues from '../../../helpers/initialValues';
import { useHandleUserVariables } from '../hooks';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

import PaisSelector from '../../Dashboard/UserAdmin/PaisSelector';
import EmpresaSelector from '../../Dashboard/UserCountry/EmpresaSelector';
import FincasSelector from '../../Dashboard/UserComp/FincasSelector';

const handleActions = (userType) => {
    switch (userType) {
        case 0:
            return <PaisSelector />;
        case 1:
            return <EmpresaSelector />;
        case 2:
            return <FincasSelector />;
        default:
            return null;
    }
};

export default function UserCreate() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(USER_DEF);
    const userVariables = useHandleUserVariables();

    const [
        addUser,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(USER_ADD);

    if (GlobalStore.userType > 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const def = JSON.parse(data.definicion);

    return (
        <React.Fragment>
            <div className="breadcrum">
                <Link to={ROUTES.READ_USERS}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.registered_users')}
                </Link>
            </div>
            <h2>{t('crud.create_user')}</h2>
            {mutationLoading && <CompLoading />}
            <Err mutationError={mutationError} />
            <Formik
                initialValues={initialValues(def)}
                validationSchema={validationSchema(def)}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    await addUser({
                        variables: {
                            ...values,
                            ...userVariables,
                        },
                    });
                    navigate(ROUTES.READ_USERS);
                }}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <FieldBuilder def={def} />
                            <div className="custom-select">
                                {handleActions( GlobalStore.userType )}
                            </div>
                            <div className="btn-actions">
                                <Button
                                    type="submit"
                                    label={t('common:save')}
                                    icon="pi pi-check"
                                    disabled={mutationLoading}
                                />
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}

