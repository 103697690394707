//@ts-check

import React, { useContext } from 'react';
import * as ROUTES from '../../constants/routes';
import { NavLink } from 'react-router-dom';
import { MobXProviderContext } from 'mobx-react';

import { useTranslation } from 'react-i18next';

import {
    FaHome,
    FaUserEdit,
    FaBuilding,
    FaChartBar,
    FaTools,
    FaUsers,
    FaPlusCircle,
} from 'react-icons/fa';

export default function CountryMenu() {
    const { GlobalStore } = useContext(MobXProviderContext);

    const { t } = useTranslation();

    return (
        <ul>
            <li>
                <NavLink title={t('common:home')} to={ROUTES.DASHBOARD}>
                    <FaHome />
                    <span>{t('common:home')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('common:edit_profile')} to={`${ROUTES.UPDATE_USER}/${GlobalStore.user.uid}`}>
                    <FaUserEdit />
                    <span>{t('common:edit_profile')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.configuration_table')} to={ROUTES.READ_CONFIG_GLOBAL}>
                    <FaTools />
                    <span>{t('menu.configuration_table')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.results')} to={ROUTES.READ_COUNTRY_RESULTS}>
                    <FaChartBar />
                    <span>{t('menu.results')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.companies_admins')} to={ROUTES.READ_USERS}>
                    <FaUsers />
                    <span>{t('menu.companies_admins')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.companies')} to={ROUTES.READ_EMPRESAS}>
                    <FaBuilding />
                    <span>{t('menu.companies')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.create_company')} to={ROUTES.CREATE_EMPRESA}>
                    <FaPlusCircle />
                    <span>{t('menu.create_company')}</span>
                </NavLink>
            </li>
        </ul>
    );
}
