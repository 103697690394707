import { gql } from '@apollo/client';

const RESIDUOS_CALC = gql`
  query calculoResiduos(
    $tipoResiduo: String!
    $tipoTratamientoRSM: String
    $totalMensualRSM: Float
    $tipoTratamientoARO: String
    $promedioHorasLaboradasDia: Float
    $promedioDiasLaboradasMes: Float
    $cantidadPersonalMensual: Float
    $tipoTratamientoARP: String
    $totalARPVertida: Float
    $concentracionMOenARP: Float
    $empresa: ID!
  ) {
    calculoResiduos(
      tipoResiduo: $tipoResiduo
      tipoTratamientoRSM: $tipoTratamientoRSM
      totalMensualRSM: $totalMensualRSM
      tipoTratamientoARO: $tipoTratamientoARO
      promedioHorasLaboradasDia: $promedioHorasLaboradasDia
      promedioDiasLaboradasMes: $promedioDiasLaboradasMes
      cantidadPersonalMensual: $cantidadPersonalMensual
      tipoTratamientoARP: $tipoTratamientoARP
      totalARPVertida: $totalARPVertida
      concentracionMOenARP: $concentracionMOenARP
      empresa: $empresa
    )
  }
`;

export { RESIDUOS_CALC };
