import React, {useRef, useState} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Row } from 'primereact/row';
// import { Chart } from 'primereact/chart';
import { useTranslation } from 'react-i18next';


import { REPORT_HUELLA_AGUA } from '../../../../queries/queries_results';
import ChartColumnsTabla1Contenedor from './graficas/ChartColumnsTabla1Contenedor';
import { CompLoading } from '../../../Loaders';
import { rounder, numberFormatTxt } from '../../../../helpers/rounder';
import Err from '../../../Err';

export default function HuellaAguaBloque(props) {
    const { t, i18n } = useTranslation();
    // ===== Necesario para exportar excel, pdf =====
    const [exportando, setExportando] = useState(false);
    const dt1 = useRef(null);
    const dt2 = useRef(null);

    // ==============================================

    const { loading, error, data } = useQuery(REPORT_HUELLA_AGUA, {
        fetchPolicy: 'no-cache',
        variables: { ...props }
    });

    if (loading) { return <CompLoading />; }
    if (error || !(data && data.reporteHuellaAgua)) {
        const err = error || '';
        return (
            <div className="sinDatos">
                <span title={err}> { t('common:no_data') } </span>
            </div>
        );
    }
    if (error) { return <Err error={error} />; }

    const dataParsed = JSON.parse(data.reporteHuellaAgua);
    const [tableData1, tableData2] = dataParsed;

    // const dynamicColumns = (columns) => {
    //     return _.map(columns, (col, i) => (
    //         <Column key={i} field={col.field} header={t('tables.'+col.field)} />
    //     ));
    // };

    const footerGroup = (footer) => {
        if (footer === null) return null;
        const values = _.map(footer.value, (value, i) => {
            const roundedValue = numberFormatTxt(value);
            return <Column footer={roundedValue} key={i} />;
        });
        return (
            <ColumnGroup>
                <Row>
                    <Column footer={footer.label} />
                    {values}
                </Row>
            </ColumnGroup>
        );
    };

    // =============================================================================

    const exportCSV = async (tabla, selectionOnly) => {
        await setExportando(true);
        tabla.current.exportCSV({ selectionOnly });
    };

    // https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling
    // https://github.com/ShanaMaid/sheetjs-style
    const exportExcel = (tabla) => {
        // console.log('xslx: ', values);
        const v = (tabla === 'dt1')? tableData1.values: tableData2.values;
        import('sheetjs-style').then(xlsx => {
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.json_to_sheet(v);
            xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
            xlsx.writeFile(wb, 'test.xlsx');
        });
    };

    const generarPdf = () => {
        const refTablaA = document.querySelector('#tablaResumenA table.p-datatable-table');
        const refTablaB = document.querySelector('#tablaResumenB table.p-datatable-table');
        if (refTablaA && refTablaB) {
            // Preparación de la tabla para incorporarla al pdf
            refTablaA.setAttribute('id', 'reftablaResumenA');
            refTablaA.querySelectorAll('#reftablaResumenA .p-datatable-tbody>tr>td>.p-column-title').forEach(el => el.remove());
            refTablaB.setAttribute('id', 'reftablaResumenB');
            refTablaB.querySelectorAll('#reftablaResumenB .p-datatable-tbody>tr>td>.p-column-title').forEach(el => el.remove());
            // Lazyload
            import('./utils_pdf').then(({ createDocPdf, addHeaders, addFooters, add2ChartsPngToPdf, createTablePdf, addTablaFiltroPdf }) => {
                const doc = createDocPdf('portrait');
                doc.setFont('helvetica', 'italic');
                let finalY = 17;
                doc.setFontSize(14);
                doc.setTextColor(30,30,50);
                // @ts-ignore
                doc.text(t('results.water_footprint'), 105, finalY+8, null, null, 'center');
                finalY = addTablaFiltroPdf({
                    doc: doc,
                    data: {
                        empresa: { title: t('field.company'), value: props.datos.companyName},
                        tecnico: { title: t('field.tecnico'), value: props.datos.tecnicoName},
                        alcance: { title: t('fields.alcance'), value: props.alcance?t('domains:alcance.'+props.alcance): t('crud.all_') },
                        anno: { title: t('field.year'), value: props.anio},
                        mes: { title: t('field.month'), value: props.mes?props.mes:t('crud.all_') },
                        finca: { title: t('fields.finca'), value: props.datos.fincaName || t('crud.all_') }
                    },
                    config: { startY: finalY + 15 }
                });
                doc.setFontSize(11);
                doc.setTextColor(70,70,90);
                doc.text(t('results.results_by_impact_type'), 14, finalY + 10);
                finalY = createTablePdf({
                    doc: doc,
                    config: {
                        html: '#reftablaResumenA',
                        startY: finalY + 12,
                        margin: { top: 20 },
                        styles: { fontSize: 6.5, cellPadding: 1 },
                        headStyles: { halign: 'center', cellPadding: 1 }, // ?????????????????
                        bodyStyles: { halign: 'center', textColor: 10 },
                        footStyles: { halign: 'center' },
                        columnStyles: { 0: {halign: 'left'}}
                    }
                });
                doc.setFontSize(11);
                doc.setTextColor(70,70,90);
                doc.text(t('results.results_at_consumption_level'), 14, finalY + 10);
                finalY = createTablePdf({
                    doc: doc,
                    config: {
                        html: '#reftablaResumenB',
                        startY: finalY + 12,
                        margin: { top: 20 },
                        styles: { fontSize: 6.5, cellPadding: 1 },
                        headStyles: { halign: 'center', cellPadding: 1 }, // ?????????????????
                        bodyStyles: { halign: 'center', textColor: 10 },
                        footStyles: { halign: 'center' },
                        columnStyles: { 0: {halign: 'left'}}
                    }
                });
                finalY = add2ChartsPngToPdf({doc: doc, finalY: finalY, class: 'graficaWrapperTablaB' });
                addHeaders(doc, i18n.language.split('-')[0]);
                addFooters(doc);
                let name = (i18n.language.split('-')[0] === 'es')
                    ? 'WBF_ResumenHuellaDeAgua_'
                    : 'WBF_SummaryWaterFootprint_';
                doc.save(name + (new Date()).toISOString().split('.')[0].replace(/-|:/g,'') + '.pdf');
            }).catch(err => {
                console.log('--------------------------------------------');
                console.error(err);
                console.log('--------------------------------------------');
            });
        }
    };

    const header = (id) =>  {
        const varToString = varObj => Object.keys(varObj)[0];
        const idt = varToString(id);

        return (
            <div className="flex align-items-right export-buttons">
                <Button type="button" icon="pi pi-file" onClick={() => exportCSV(id, false)} className="mr-2" title={t('commons:export_csv')} />
                &nbsp;
                <Button type="button" icon="pi pi-file-excel" onClick={() => exportExcel(idt)} className="mr-2" title={t('commons:export_xls')} />
                &nbsp;
                <Button type="button" icon="pi pi-file-pdf" onClick={() => generarPdf()} className="mr-2" title={t('commons:export_pdf')} />
            </div>
        );
    };


    // =============================================================================

    const traduce = (tb) => {
        const traTab = JSON.parse(JSON.stringify(tb.values));
        _.map(traTab, (value) => {
            if (i18n.exists('domains:impacto.' + value['impacto'])) {
                value['impacto'] = t('domains:impacto.' + value['impacto']);
            }
            if (i18n.exists('domains:fuenteEmision.' + value['fuente'])) {
                value['fuente'] = t('domains:fuenteEmision.' + value['fuente']);
            }
        });
        return traTab;
    };

    const columnasVisibles = (columns) => {
        const getHeader = (col) => {
            const u = t('tables.h_'+col.field+'_u');
            return (<> {t('tables.h_'+col.field)} <br/><small> {u===''?'':'('+u+')'} </small> </>);
        };
        return _.map(columns, (col, i) => {
            const r = getHeader(col);
            return (<Column key={i} field={col.field} header={r} align={i?'center':'left'} alignHeader="center"/>);
        });
    };

    const columnasExportables = (columns) => _.map(columns, (col, i) => (
        <Column key={i} field={col.field} header={t('tables.h_'+col.field)+' ('+t('tables.h_'+col.field+'_u')+')'} align={i?'center':'left'} alignHeader="center"/>
    ));

    const tableFirst = () => {
        const { columns, footer } = tableData1;
        const traTab = traduce(tableData1);
        const roundedData = rounder(traTab, 10);
        return (
            <div>
                <DataTable id="tablaResumenA" value={roundedData} footerColumnGroup={footerGroup(footer)} header={() => header({dt1})}>
                    {columnasVisibles(columns)}
                </DataTable>
                {exportando && (<div style={{display:"none"}}>
                <DataTable ref={dt1} value={roundedData} footerColumnGroup={footerGroup(footer)}>
                    {columnasExportables(columns)}
                </DataTable>
                </div>)}
            </div>
        );
    };

    const tableSecond = () => {
        const { columns, footer } = tableData2;
        const traTab = traduce(tableData2);
        const roundedData = rounder(traTab, 10);
        tableData2.vDefault = 'cosumoTotalm3';
        tableData2.uDefault = 'm³';
        return (
            <div>
                <DataTable id="tablaResumenB" value={roundedData} footerColumnGroup={footerGroup(footer)} header={() => header({dt2})}>
                    {columnasVisibles(columns)}
                </DataTable>
                {exportando && (
                    <div style={{display:"none"}}>
                        <DataTable ref={dt2} value={roundedData} footerColumnGroup={footerGroup(footer)}>
                            {columnasExportables(columns)}
                        </DataTable>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="huella-agua">
            <h1> {t('results.water_footprint')} </h1>
            <div className="result-item results-1">
                <h3> {t('results.results_by_impact_type')} </h3>
                {tableFirst()}
            </div>
            <div className="result-item results-2">
                <h3> {t('results.results_at_consumption_level')} </h3>
                {tableSecond()}
                <ChartColumnsTabla1Contenedor data={{...tableData2, title: 'results_at_consumption_level'}} />
            </div>
        </div>
    );
}
