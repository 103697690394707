////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { Password as PasswordInput } from 'primereact/password';


import HandleLabel from './HandleLabel';
import Error from './Error';


export default function Password({ field, required, disabled }) {
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname } = field;
    const { handleChange, isValid, values, touched, errors } = formik;

    const handleKey = (e) => {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <PasswordInput
                name={fieldname}
                id={fieldname}
                onChange={handleChange}
                onKeyDown={handleKey}
                value={values[fieldname]}
                disabled={disabled}
            />
            <Error
                isValid={isValid}
                touched={touched[fieldname]}
                errors={errors[fieldname]}
            />
        </div>
    );
}
