import React from "react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from 'react-i18next';

export function HandleConfigSelect({configuraciones, config, setConfig}) {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { t } = useTranslation();

    const options = _.map(configuraciones, ({ id, anio }) => {
        return {
            label: anio,
            value: id,
        };
    });
    return (
        <div className="filter-input">
            <label htmlFor="config">{t('field.configuration')}*</label>
            <Dropdown
                name="config"
                inputId={idInput}
                onChange={({ target: { value } }) => setConfig(value)}
                value={config}
                options={options}
                placeholder={t('crud.select_the_year_of_configuration')}
            />
        </div>
    );
}

export function HandleAnioSelect({anio, setAnio}) {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { t } = useTranslation();
    const currentYear = Number((new Date()).getFullYear());
    const range = _.rangeRight(currentYear - 5, currentYear + 1);
    const options = _.map(range, (year) => {
        return {
            label: year,
            value: year
        };
    });
    return (
        <div className="filter-input">
            <label htmlFor="annio">{t('field.year')}*</label>
            <Dropdown
                name="annio"
                id="annio"
                inputId={idInput}
                onChange={({ target: { value } }) => setAnio(value)}
                value={anio}
                options={options}
                placeholder={t('crud.select_the_year')}
            />
        </div>
    );
}

export function HandleMesSelect({mes, setMes}) {
    const { t, i18n } = useTranslation();
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const months = i18n.getResourceBundle(i18n.language.split('-')[0], 'prime').monthNames;
    const options = _.map(months, (month) => {
        return {
            label: _.upperFirst(month),
            value: month,
        };
    });
    options.splice(0, 0, {
        label: "Todos",
        value: null,
    });
    return (
        <div className="filter-input">
            <label htmlFor="mes">{t('field.month')}</label>
            <Dropdown
                name="mes"
                id="mes"
                inputId={idInput}
                onChange={({ target: { value } }) => setMes(value)}
                value={mes}
                options={options}
                placeholder={t('crud.select_the_month')}
            />
        </div>
    );
}

export function HandleFincaSelect({fincas, finca, setFinca}) {
    const { t } = useTranslation();
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const options = _.map(fincas, ({ id, nombreFinca }) => {
        return {
            label: nombreFinca,
            value: { id: id, nombreFinca: nombreFinca },
        };
    });
    options.splice(0, 0, {
        label: t('crud.all'),
        value: null,
    });
    return (
        <div className="filter-input">
            <label htmlFor="finca">{t('field.farm')}</label>
            <Dropdown
                name="finca"
                id="finca"
                inputId={idInput}
                onChange={({ target: { value } }) => setFinca(value)}
                value={finca}
                options={options}
                placeholder={t('dashboard.select_farm')}
            />
        </div>
    );
}

export function HandleAlcanceSelect({type, alcance, setAlcance}) {
    const { t } = useTranslation();
    if (type !== 'CARBONO') { return null; }
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const options = [
        {   label: t('crud.all_'), value: null },
        {   label: t("domains:alcance.emisiones_directas_de_gei"), value: "1" },
        {   label: t("domains:alcance.emisiones_indirectas_de_gei_por_energia_importada"), value: "2" },
        {   label: t("domains:alcance.emisiones_indirectas_de_gei_por_transporte"), value: "4" },
        {   label: t("domains:alcance.emisiones_indirectas_de_gei_por_otras_fuentes"), value: "3" }
    ];
    return (
        <div className="filter-input">
            <label htmlFor="alcance">{t('field.scope')}</label>
            <Dropdown
                name="alcance"
                inputId={idInput}
                onChange={({ target: { value } }) => setAlcance(value)}
                value={alcance}
                options={options}
                placeholder={t('field.scope')}
            />
        </div>
    );
}

export function HandleFuenteSelect({type, fuente, setFuente}) {
    const { t } = useTranslation();
    if (type !== 'AGUA') { return null; }
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const options = [
        { label: t('domains:sistema.todos'), value: null },
        { label: t('domains:sistema.campo'), value: 'campo' },
        { label: t('domains:sistema.planta_empacadora'), value: 'planta_empacadora' }
    ];
    return (
        <div className="filter-input">
            <label htmlFor="fuente">{t('tables.sistema')}</label>
            <Dropdown
                name="sistema"
                inputId={idInput}
                onChange={({ target: { value } }) => setFuente(value)}
                value={fuente}
                options={options}
                placeholder={t('tables.sistema')}
            />
        </div>
    );
}
