import React from "react";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { useTranslation } from 'react-i18next';

import { numberFormatTxt } from "../../helpers/rounder";

export default function Computed({ field, data }) {
    const { i18n, t } = useTranslation();
    const { fieldname, label, step } = field; // label

    const handleValue = () => {
        if (!data) {
            return '';
        }
        const findValue = _.find(data, { fieldname });
        if (!findValue) {
            return '';
        }
        if (isNaN(+findValue.value)) {
            if (i18n.exists(`domains:${findValue.fieldname}.${findValue.value}`)) {
                return t(`domains:${findValue.fieldname}.${findValue.value}`);
            } else {
                return findValue.value;
            }
        }
        let mf = 2;
        if (step) {
            const tn = (step - step.toFixed()).toString().split('-');
            mf = (tn.length>1) ? 1*tn[1] : tn[0].length - 2;
        }
        mf = (mf < 0) ? 0 : mf;
        return numberFormatTxt(findValue.value, mf);
    };

    return (
        <div className="input-text">
            <label htmlFor={fieldname}> {t('fields.'+label)} </label>
            <InputText
                type="text"
                disabled
                name={fieldname}
                id={fieldname}
                value={handleValue()}
            />
        </div>
    );
}
