// //@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { TabView, TabPanel } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../../constants/routes';
import { CompLoading } from '../../../Loaders';
import TableBuilder from './TableBuilder';
import Err from '../../../Err';

import './config.scss';


const TABLES_DEF = gql`
  {
    definicion(tipoDefinicion: "configuracionTablas")
  }
`;

export default function EditConfig() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    let { id, anio } = useParams();

    anio = anio || null;

    const { loading, error, data } = useQuery(TABLES_DEF);

    if (GlobalStore.userType > 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const def = JSON.parse(data.definicion);

    const handleTables = () => {
        const sortingDef = _.reduceRight(
            def,
            (acc, table, tableName) => {
                return {
                    ...acc,
                    [tableName]: table,
                };
            },
            {}
        );
        return _.map(sortingDef, (table, tableName) => {
            return (
                <TabPanel header={t('menu.'+table.name)} key={tableName}>
                    <TableBuilder
                        key={tableName}
                        id={id}
                        def={table.fields}
                        tableName={tableName}
                    />
                </TabPanel>
            );
        });
    };

    const handleBreadcrum = () => {
        const link = () => {
            switch (GlobalStore.userType) {
                case 1: {
                    return ROUTES.READ_CONFIG_GLOBAL;
                }
                case 2: {
                    return ROUTES.READ_CONFIG;
                }
                default: {
                    return null;
                }
            }
        };
        return (
            <div className="breadcrum">
                <Link to={link()}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.configuration_sheets')}
                </Link>
            </div>
        );
    };

    return (
        <div className="page-config">
            {handleBreadcrum()}
            <h1> {t('crud.calculation_factors')} {anio}</h1>
            <ul className="label-colors">
                <li>
                    <span> {t('crud.water_footprint')} </span> {t('crud.calculation_factor_1')}
                </li>
                <li>
                    <span> {t('crud.carbon_footprint')} </span> {t('crud.calculation_factor_2')}
                </li>
            </ul>
            <TabView>{handleTables()}</TabView>
        </div>
    );
}
