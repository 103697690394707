//@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

export default function UserAdmin() {
    const { t } = useTranslation();
    const { GlobalStore } = useContext(MobXProviderContext);
    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('dashboard.useradmin_title')}
                </title>
            </Helmet>
            <h1>{t('common:welcome')}, {GlobalStore.user.displayName}</h1>
            <div className="note-digits">
                {t('dashboard.note_digits')}
            </div>
        </React.Fragment>
    );
}
