import { gql } from '@apollo/client';

const FINCA_DEF = gql`
    {
        definicion(tipoDefinicion: "fincaCrear")
    }
`;

const FINCA_GET = gql`
    query FincaGet($id: ID!) {
        finca(id: $id) {
            id
            nombreFinca
            coordinates
            tamano
            tipo
        }
        definicion(tipoDefinicion: "fincaActualizar")
    }
`;

const FINCA_EDIT = gql`
    mutation EditFinca(
        $id: ID,
        $uid: ID!,
        $nombreFinca: String!,
        $coordinates: String!,
        $tamano: Float,
        $tipo: String
    ) {
        fincaActualizar(
            id: $id,
            uid: $uid,
            nombreFinca: $nombreFinca,
            coordinates: $coordinates,
            tamano: $tamano,
            tipo: $tipo
        )
    }
`;

const FINCA_ADD = gql`
    mutation FincaAdd(
        $uid: ID!,
        $nombreFinca: String!,
        $coordinates: String!,
        $tamano: Float,
        $tipo: String
    ) {
        fincaCrear(
            uid: $uid,
            nombreFinca: $nombreFinca,
            coordinates: $coordinates,
            tamano: $tamano,
            tipo: $tipo
        ) {
            id
        }
    }
`;

const FINCAS_LIST = gql`
    query FincaList(
        $uid: ID
    ) {
        fincas(uid: $uid) {
            id
            nombreFinca
            coordinates
            tamano
            tipo
        }
    }
`;

export { FINCA_DEF, FINCA_GET, FINCA_EDIT, FINCA_ADD, FINCAS_LIST };
