////@ts-check

import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { rounder, numberFormatTxt } from '../../../../../helpers/rounder';

export default function HuellaCarbonoVariableTabla({ data }) {
    const { t, i18n } = useTranslation();
    const dt = useRef(null);

    const [exportando, setExportando] = useState(false);
    const { columns, values, footer, formulario } = data;
    const roundedData = values ? rounder(values, 10) : null;

    /*
    // useEffect(() => {
        if (values) {
            const fld = _.filter(columns, (col) => {
                return (col.type === 'string');
            });
            _.map(values, (value) => {
                _.map(fld, (key) => {
                    console.log('- values: ', key.field, ' - ', value[key.field]);
                    if (i18n.exists('domains:' + key.field + '.' + value[key.field])) {
                        value[key.field] = t('domains:' + key.field + '.' + value[key.field]);
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [values]);
*/
    const traduceCell = (key, val) => {
        if (i18n.exists('domains:' + key + '.' + val)) {
            return t('domains:' + key + '.' + val);
        }
        return val;
    };

    if (!values) return (<div className="mensaje_no_data"> { t('common:no_registers') } </div>);

    const invoiceDownloadHandler = (fileOrig, textOrig) => {
        // console.log('fileOrig (fileData): ', fileOrig, 'textOrig (ubicacionDocumento): ', textOrig);
        if (fileOrig) {
            fetch(fileOrig, { method: 'GET' })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', textOrig);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        }
    };

    const templateCell = (node, column) => {
        if (column.field === 'ubicacionDocumento') {
            return (<span className="enlaceDescarga" onClick={() => invoiceDownloadHandler(node['fileData'], node[column.field])}>
                {node[column.field]}
            </span>);
        }
        return (<> {traduceCell(column.field, node[column.field])} </>);
    };

    const nombreColumna = (campo) => {
        if (campo === 'consumo') {
            switch (formulario) {
                case 'combustion-estacionaria': return 'consumo_kg_l';
                case 'combustion-movil': return 'consumo_l';
                case 'mantenimiento': return 'consumo_kg_l';
                case 'refrigeracion': return 'consumo_kg';
                default: return campo;
            }
        }
        return campo;
    };


    const columnasExportables = (columns) => _.map(columns, (col, i) => {
        const colField = 'fields.' + nombreColumna(col.field);
        return (<Column key={i} field={col.field} header={t(colField)} align={i ? 'center' : 'left'} alignHeader="center" />);
    });

    const columnasVisibles = (columns) => {
        const getHeader = (col) => {
            const colField = 'fields.' + nombreColumna(col.field);
            return (<> {t(colField)} </>);
        };
        // traduceFuenteEmision();
        return _.map(columns, (col, i) => {
            const r = getHeader(col);
            return (<Column
                key={i}
                field={col.field}
                header={r} align={i ? 'center' : 'left'}
                alignHeader="center"
                body={templateCell}
            />);
        });
    };

    const footerGroup = (footer) => {
        if (!footer || footer === null) return null;
        const values = _.map(footer.values, (value, i) => {
            const roundedValue = _.isNumber(value) ? numberFormatTxt(value) : '';
            return <Column footer={roundedValue} key={i} align="center" />;
        });

        return (
            <ColumnGroup>
                <Row>
                    <Column footer={footer.label} align="center" />
                    {values}
                </Row>
            </ColumnGroup>
        );
    };

    const exportCSV = async (selectionOnly) => {
        await setExportando(true);
        dt.current.exportCSV({ selectionOnly });
    };

    // https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling
    // https://github.com/ShanaMaid/sheetjs-style
    const exportExcel = () => {
        console.log('xslx: ', values);
        import('sheetjs-style').then(xlsx => {
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.json_to_sheet(values);
            xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
            xlsx.writeFile(wb, 'test.xlsx');
        });
    };

    /*
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(() => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            window.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    };
*/
    // <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
    // <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info ml-auto" data-pr-tooltip="Selection Only" />

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
            &nbsp;
            <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="mr-2" data-pr-tooltip="XLS" />
        </div>
    );

    return (
        <div>
            <DataTable value={roundedData} footerColumnGroup={footerGroup(footer)} header={header}>
                {columnasVisibles(columns)}
            </DataTable>
            {exportando && (<div style={{ display: "none" }}>
                <DataTable ref={dt} value={roundedData} footerColumnGroup={footerGroup(footer)}>
                    {columnasExportables(columns)}
                </DataTable>
            </div>)}
        </div>
    );
}
