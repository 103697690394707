////@ts-check

import React, {useState} from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import HuellaCarbonoResumen1 from './HuellaCarbonoResumen1';
import HuellaCarbonoResumen2 from './HuellaCarbonoResumen2';
import HuellaCarbonoVariables from './HuellaCarbonoVariables';
// import { Button } from 'primereact/button';


import {
    FaChartPie,
    FaLightbulb,
    FaIndustry,
    FaTractor,
    FaWrench,
    FaSnowflake,
    FaSeedling,
    FaTrashAlt,
    // FaTint,
    FaStickerMule
  } from 'react-icons/fa';

import {
    GiTurd,
    GiForest,
    GiCoalPile
} from "react-icons/gi";

export default function HuellaCarbonoBloque(props) {
    const { t } = useTranslation();
    const [ seleccionado, setSeleccionado] = useState('resumen');

    const opts = [
        {icon: <FaLightbulb/>, value: 'electricidad', title: 'menu.electricity'},
        {icon: <FaIndustry/>, value: 'combustion-estacionaria', title: 'menu.stationary_combustion'},
        {icon: <FaTractor/>, value: 'combustion-movil', title: 'menu.mobile_combustion'},
        {icon: <FaWrench/>, value: 'mantenimiento', title: 'menu.maintenance_products'},
        {icon: <FaSnowflake/>, value: 'refrigeracion', title: 'menu.refrigeration'},
        {icon: <FaSeedling/>, value: 'fertilizantes', title: 'menu.fertilizers'},
        {icon: <FaTrashAlt/>, value: 'residuos', title: 'menu.waste'},
        // {icon: <FaTint/>, value: 'huella-agua', title: 'menu.water_footprint'},
        {icon: <FaStickerMule/>, value: 'traccion-animal-proc-digestivo', title: 'menu.traccion_animal_proc_digestivo'},
        {icon: <GiTurd/>, value: 'traccion-animal-estiercol', title: 'menu.traccion_animal_estiercol'},
        {icon: <GiForest/>, value: 'uso-suelo-forestal-forestal', title: 'menu.uso_suelo_forestal_forestal'},
        {icon: <GiForest/>, value: 'uso-suelo-cultivo-forestal', title: 'menu.uso_suelo_cultivo_forestal'},
        {icon: <GiForest/>, value: 'uso-suelo-forestal-cultivo', title: 'menu.uso_suelo_forestal_cultivo'},
        {icon: <GiCoalPile/>, value: 'uso-suelo-carbono', title: 'menu.uso_suelo_carbono'},
    ];

    const nivelesList = [
        'fuenteEmision_alcance_producto',
        'fuenteEmision_producto_alcance',
        'alcance_fuenteEmision_producto',
        'alcance_producto_fuenteEmision',
        'producto_alcance_fuenteEmision',
        'producto_fuenteEmision_alcance',
    ];

    const styleButton = (opt) => {
        return ((opt===seleccionado) && 'botonInformeActivo') || 'botonInforme';
    };

    const botoneraSeleccion = () => {
        return opts.map((op, i) => (<Button
            key = {i}
            onClick={() => setSeleccionado(op.value)}
            // label = {op.title}
            title= {t(op.title)}
            icon= {op.icon}
            className={styleButton(op.value)}
        />));
    };

    return (
        <div className="huella-carbono">
            <h1> {t('crud.carbon_footprint')} </h1>
            <div className="botoneraInformes">
                <Button onClick={() => setSeleccionado('resumen')} className={styleButton('resumen')}>
                    <FaChartPie/>
                    <span style={{marginLeft: '.5em'}}>{t('common:summary')}</span>
                </Button>
                &nbsp;
                {botoneraSeleccion()}
            </div>
            {(seleccionado === 'resumen') && (<>
                <HuellaCarbonoResumen1 requestData={{...props, pais: null}} />
                <HuellaCarbonoResumen2 requestData={{...props, pais: null}} nivelesList={nivelesList} formato={'producto'}/>
            </>)}
            {(seleccionado !== 'resumen') && (<HuellaCarbonoVariables requestData={{...props, pais: null, formulario: seleccionado}} />)}
        </div>
    );
}


