////@ts-check

import React, { useContext } from 'react';
import _ from 'lodash';
import { MobXProviderContext } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import { useQuery } from '@apollo/client';
import { CompLoading } from '../../Loaders';
import { DASH_COMP } from '../../../queries/queries_dashboard';
import Err from '../../Err';

import { useTranslation, Trans } from 'react-i18next';

export default function UserComp() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(DASH_COMP, {
        fetchPolicy: 'no-cache',
        variables: {
            uid: GlobalStore.user.uid,
            pais: GlobalStore.countryId,
            empresa: GlobalStore.compId,
        },
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const listAnios = _.map(data.configuraciones, ({ anio }) => {
        return <li key={anio}>{anio}</li>;
    });

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('dashboard.usercomp_title')}
                </title>
            </Helmet>
            <h1>{t('dashboard.usercomp_type')}: {data.empresa.nombreEmpresa}</h1>
            <h3>{t('common:welcome')}, {GlobalStore.user.displayName}</h3>
            <div className="note-digits">
                {t('dashboard.note_digits')}
            </div>
            <div style={{ borderTop: '1px dotted lightgrey' }}>
                <ul>
                    <li>
                        <Trans i18nKey="dashboard.total_admin"> </Trans> {data.usuarios.length}
                    </li>
                    <li>
                        <Trans i18nKey="dashboard.total_farms"> </Trans> {data.fincas.length}
                    </li>
                    <li>
                        <Trans i18nKey="dashboard.total_sheet"> </Trans> {data.configuraciones.length}
                    </li>
                    <li>
                        <Trans i18nKey="dashboard.years_sheet"> </Trans>
                    </li>
                    <ul>{listAnios}</ul>
                </ul>
            </div>
        </React.Fragment>
    );
}
