////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../constants/routes';
import { USER_GET, USER_EDIT } from '../../../queries/queries_user';
import FieldBuilder from '../../../helpers/FieldBuilder';
import validationSchema from '../../../helpers/validationSchema';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

import PaisSelector from '../../Dashboard/UserAdmin/PaisSelector';
import EmpresaSelector from '../../Dashboard/UserCountry/EmpresaSelector';
import FincasSelector from '../../Dashboard/UserComp/FincasSelector';


export default function UserEdit() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();

    const handleActions = (userType) => {
        if (id === GlobalStore.user.uid) return null;
        switch (userType) {
            case 0:
                return <PaisSelector />;
            case 1:
                return <EmpresaSelector />;
            case 2:
                return <FincasSelector />;
            default:
                return null;
        }
    };

    const { loading, error, data } = useQuery(USER_GET, {
        variables: {
            id,
        },
    });

    const [
        editUser,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(USER_EDIT);

    if (!id) {
        return null;
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    //Def from GraphQL
    const def = JSON.parse(data.definicion);

    const handleSubmit = async (values) => {
        await editUser({
            variables: {
                ...values,
            },
            refetchQueries: [
                {
                    query: USER_GET,
                    variables: {
                        id,
                    },
                },
            ],
        });
        navigate(ROUTES.READ_USERS);
    };

    const handleFincas = (userType, fincas) => {
        if (userType === 2) {
            return _.map(fincas, (finca) => finca.id);
        }
        return [];
    };

    const handleInitialValues = () => {
        if (!_.isEmpty(data.usuario.fincas)) {
            return {
                ...data.usuario,
                pais: data.usuario.pais.id,
                empresa: data.usuario.empresa.id,
                fincas: handleFincas(GlobalStore.userType, data.usuario.fincas),
            };
        }
        if (data.usuario.empresa.id !== null) {
            // eslint-disable-next-line no-unused-vars
            const { fincas, ...cleanData } = data.usuario;
            return {
                ...cleanData,
                pais: data.usuario.pais.id,
                empresa: data.usuario.empresa.id,
            };
        }
        if (data.usuario.pais.id !== null) {
            // eslint-disable-next-line no-unused-vars
            const { empresa, fincas, ...cleanData } = data.usuario;
            return {
                ...cleanData,
                pais: data.usuario.pais.id,
            };
        }
        // eslint-disable-next-line no-unused-vars
        const { pais, empresa, fincas, ...cleanData } = data.usuario;
        return {
            ...cleanData,
        };
    };

    return (
        <React.Fragment>
            <div className="breadcrum">
                <Link to={ROUTES.READ_USERS}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.registered_users')}
                </Link>
            </div>
            <h2> {t('crud.user_update')} </h2>
            {mutationLoading && <CompLoading />}
            <Err mutationError={mutationError} />
            <Formik
                initialValues={handleInitialValues()}
                enableReinitialize={true}
                validationSchema={validationSchema(def)}
                onSubmit={handleSubmit}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="p-inputgroup">
                                <FieldBuilder def={def} />
                            </div>
                            <div className="custom-select">
                                {handleActions(GlobalStore.userType)}
                            </div>
                            <div className="btn-actions">
                                <Button
                                    type="submit"
                                    label={t('common:save')}
                                    icon="pi pi-check"
                                    disabled={mutationLoading}
                                />
                                <Button
                                    type="button"
                                    label={t('common:cancel')}
                                    onClick={() => navigate(ROUTES.READ_USERS)}
                                    disabled={mutationLoading}
                                />
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
