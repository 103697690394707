////@ts-check

import React, {useState} from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Dropdown } from "primereact/dropdown";

import { REPORT_HUELLA_CARBONO } from '../../../../queries/queries_results';
import { CompLoading } from '../../../Loaders';
import HuellaCarbonoResumenTabla2 from './tablas/HuellaCarbonoResumenTabla2';
import ChartDoughnutTabla2Contenedor from './graficas/ChartDoughnutTabla2Contenedor';
import Err from '../../../Err';

export default function HuellaCarbonoResumen2({requestData, nivelesList, formato}) {
    const { t, i18n } = useTranslation();
    const [niveles, setNiveles ] = useState(nivelesList[0] || 'fuenteEmision_alcance_producto'); // setNiveles
    const { loading, error, data } = useQuery(REPORT_HUELLA_CARBONO, {
        fetchPolicy: 'no-cache',
        variables: {
            ...requestData,
            formato: formato // ['resumen' | 'producto' | 'subfuente' | 'categoria']
        }
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const dataParsed = JSON.parse(data.reporteHuellaCarbono);

    const getOptionsNiveles = () => {
        const nivelLabel = (v) => {
            const r = v.split('_');
            for (let i=0; i<r.length; i++) {
                r[i] = t('tables.h_'+r[i]);
            }
            return r.join(' > ');
        };
        const options = [];
        for (let i=0; i<nivelesList.length; i++) {
            options.push({
                value: nivelesList[i],
                label: nivelLabel(nivelesList[i])
            });
        }
        return options;
    };

    const generarPdf = () => {
        const refTabla = document.querySelector('#tablaResumen2 .p-treetable-wrapper > table');
        if (refTabla) {
            // Preparación de la tabla para incorporarla al pdf
            refTabla.setAttribute('id', 'reftablaResumen2');
            refTabla.querySelectorAll('#reftablaResumen2 .p-datatable-tbody>tr>td>.p-column-title').forEach(el => el.remove());
            // Lazyload
            import('./utils_pdf').then(({ createDocPdf, addHeaders, addFooters, addChartsPngToPdf, createTablePdf, addTablaFiltroPdf, addTablaFiltroPdfPais }) => {
                const doc = createDocPdf('portrait');
                doc.setFont('helvetica', 'italic');
                let finalY = 17;
                doc.setFontSize(14);
                doc.setTextColor(30,30,50);
                // @ts-ignore
                doc.text(t('dashboard.huella_carbono'), 105, finalY+8, null, null, 'center');
                if (formato === 'categoria') {  // utilizado en pais
                    const datosTecnicos = {
                        pais: { title: t('field.pais'), value: requestData.paisName},
                        tecnico: { title: t('field.tecnico'), value: requestData.tecnicoName},
                        anno: { title: t('field.year'), value: requestData.anio},
                        mes: { title: t('field.month'), value: requestData.mes?requestData.mes:t('crud.all_') }
                    };
                    finalY = addTablaFiltroPdfPais({ doc: doc, data: datosTecnicos, config: { startY: finalY + 15 } });
                } else { // utilizado en empresa
                    const datosTecnicos = {
                        empresa: { title: t('field.company'), value: requestData.datos.companyName},
                        tecnico: { title: t('field.tecnico'), value: requestData.datos.tecnicoName},
                        alcance: { title: t('fields.alcance'), value: requestData.alcance?t('domains:alcance.'+requestData.alcance): t('crud.all_') },
                        anno: { title: t('field.year'), value: requestData.anio},
                        mes: { title: t('field.month'), value: requestData.mes?requestData.mes:t('crud.all_') },
                        finca: { title: t('fields.finca'), value: requestData.datos.fincaName || t('crud.all_') }
                    };
                    finalY = addTablaFiltroPdf({ doc: doc, data: datosTecnicos, config: { startY: finalY + 15 } });
                }
                doc.setFontSize(11);
                doc.setTextColor(70,70,90);
                doc.text(t('results.results_by_ghg_generating_component'), 14, finalY + 10);
                finalY = createTablePdf({
                    doc: doc,
                    config: {
                        html: '#reftablaResumen2',
                        includeHiddenHtml: true,
                        // useCss: true,
                        startY: finalY + 12,
                        margin: { top: 20 },
                        styles: { fontSize: 6.5, cellPadding: 1 },
                        headStyles: { halign: 'center', cellPadding: 1 }, // ?????????????????
                        bodyStyles: { halign: 'center', lineColor: [255, 255, 255], lineWidth: 0.1 },
                        footStyles: { halign: 'center' },
                        columnStyles: { 0: {halign: 'left'}},
                        didParseCell(data) {
                            // console.log(data);
                            if (data.section === 'head' && data.column.index === 0) {
                                data.cell.styles.halign = 'left';
                                data.cell.text[1] = '    ' + data.cell.text[1];
                                data.cell.text[2] = '         ' + data.cell.text[2];
                            } else if (data.section === 'body') {
                                if ( data.row.raw._element.className === 'rowConHijos1') {
                                    data.cell.styles.fillColor = [147, 203, 244];
                                    data.cell.styles.fontSize = 7;
                                    data.cell.styles.fontStyle = 'bold';
                                    data.cell.styles.textColor = 10;
                                    if (data.column.index === 0) {
                                        data.cell.text[0] = '> ' + data.cell.text[0];
                                    }
                                } else if ( data.row.raw._element.className === 'rowConHijos2') {
                                    data.cell.styles.fillColor = [179, 229, 212];
                                    data.cell.styles.fontSize = 6.5;
                                    data.cell.styles.fontStyle = 'bold';
                                    data.cell.styles.textColor = 60;
                                    if (data.column.index === 0) {
                                        data.cell.text[0] = '     > ' + data.cell.text[0];
                                    }
                                } else {
                                    // data.cell.styles.fillColor = [150, 222, 288];
                                    data.cell.styles.fontSize = 6;
                                    data.cell.styles.fontStyle = 'normal';
                                    data.cell.styles.textColor = 90;
                                    if (data.column.index === 0) {
                                        data.cell.styles.fontSize = 6.5;
                                        data.cell.text[0] = '          > ' + data.cell.text[0];
                                    }
                                }
                            }
                        }
                    }
                });
                finalY = addChartsPngToPdf({doc: doc, finalY: finalY, class: 'graficaWrapperTabla2' });
                addHeaders(doc, i18n.language.split('-')[0]);
                addFooters(doc);
                let name = (i18n.language.split('-')[0] === 'es')
                    ? 'WBF_ResumenHuellaCarbono_ComponenteGenerador_'
                    : 'WBF_SummaryCarbonFootprint_GeneratingComponent_';
                doc.save(name + (new Date()).toISOString().split('.')[0].replace(/-|:/g,'') + '.pdf');
            }).catch(err => {
                console.log('--------------------------------------------');
                console.error(err);
                console.log('--------------------------------------------');
            });
        }
    };

    return (
        <div className="result-item results-1">
            <h3> {t('results.results_by_ghg_generating_component')} </h3>
            <div className="selectorNivel">
                <span>
                    <Dropdown value={niveles} options={getOptionsNiveles()}  onChange={(v) => setNiveles(v.value)} optionLabel="label" />
                </span>
            </div>
            <HuellaCarbonoResumenTabla2 data={dataParsed} generatePdf={generarPdf} niveles={niveles}/>
            <ChartDoughnutTabla2Contenedor data={{...dataParsed, title: 'emisiones_por_componente_generador_de_gei'}} niveles={niveles} nivelesList={nivelesList}/>
        </div>
    );
}
