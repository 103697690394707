////@ts-check

import React from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { singleRounder, numberFormatTxt } from '../../../../../helpers/rounder';

import './graficas.scss';
import colores from './colores.json';
/**
 * @param {*} param0
 *  data: {
 *      title: string       // Titulo de la gráfica
 *      variable: string    // nombre de la variable a representar
 *      values: [numbers]   // Array de valores
 *  }
 */
export default function ChartDoughnut({ data, idChart }) {
    let contColor = 1;
    const { t, i18n } = useTranslation();
    const totalAbs = data.values.reduce((ac, el) => ac + (1 * (Math.abs((el[data.variable] || 0)))), 0);
    const label = t('tables.h_' + data.variable);


    const randomHSL = (v) => {
        if (colores[v.fuenteEmision]) {
            const c =  colores[v.fuenteEmision];
            // console.log('"' + v.fuenteEmision + '": [' + c[0] + ' , ' + c[1] + ' , ' + c[2]  + ' ], ' );
            return 'hsla(' + c[0] + ',' + c[1] +'%,' + c[2] + '%, 1)';
        } else {
            const h = (36 * Math.random()) + (36 * contColor);
            contColor = (contColor + 3) % 10;
            console.log('"' + v.fuenteEmision + '": [' + (~~(h)) + ' , 80 , 70 ], ' );
            return 'hsla(' + ~~(h) + ',' + '80%,' + '70%,1)';
        }
    };

    const traduceFuenteEmision = (fuenteEmision) => {
        if (i18n.exists('menu.'+fuenteEmision)) {
            return t('menu.' + fuenteEmision);
        }
        return fuenteEmision;
    };

    const chartData = {
        labels: _.map(data.values, ({ fuenteEmision }) => traduceFuenteEmision(fuenteEmision)),
        datasets: [
            {
                label: label,
                data: _.map(data.values, (el) => singleRounder(el[data.variable])),
                backgroundColor: _.map(data.values, (v) => randomHSL(v)),
                // borderWidth: 1, // Anchura de borde
                // cutout: '80%',  // grosor de la porción
                // borderRadius: 10, // radio de la terminación de la porción
                // offset: 5   // espacio entre porciones
            }
        ]
    };

    const lightOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'left',
                align: 'start',
                title: {
                    display: true,
                    text: [t('chart.' + data.title), label + '   ' + totalAbs.toFixed(1) + ' ' + data.unidad],
                    font: { weight: 'bold' },
                    padding: {
                        top: 8,
                        bottom: 20
                    }
                },
                onHover: (evt, item, legend) => {
                    const chart = legend.chart;
                    const tooltip = chart.tooltip;
                    const chartArea = chart.chartArea;
                    tooltip.setActiveElements([{
                        datasetIndex: 0,
                        index: item.index,
                    }], {
                        x: (chartArea.left + chartArea.right) / 2,
                        y: (chartArea.top + chartArea.bottom) / 2,
                    });
                    chart.update();
                },
                labels: {
                    padding: 7,
                    generateLabels(chart) {
                        const dt = chart.data;
                        if (dt.labels.length && dt.datasets.length) {
                            const { labels: { pointStyle } } = chart.legend.options;
                            return dt.labels.map((label, i) => {
                                const meta = chart.getDatasetMeta(0);
                                const style = meta.controller.getStyle(i);
                                const value = numberFormatTxt(meta._parsed[i]);
                                return {
                                    text: label + '   ( ' + value + ' ) ',
                                    fillStyle: style.backgroundColor,
                                    strokeStyle: style.borderColor,
                                    lineWidth: style.borderWidth,
                                    color: 'rgb(30,30,30)',
                                    pointStyle: pointStyle,
                                    hidden: !chart.getDataVisibility(i),
                                    index: i // Dato necesario para "toogling" items
                                };
                            });
                        }
                        return [];
                    }
                }
            },
            datalabels: {
                display: 'auto',
                padding: 2,
                font: {
                    size: 12,
                    color: 'blue'
                },
                textStrokeWidth: 1,
                textStrokeColor: 'white',
                textShadowBlur: 3,
                textShadowColor: 'white',
                formatter: (value, ctx) => {
                    let sum = ctx.chart._metasets[0].total;
                    let percentage = Math.round((value * 100 / sum)*10)/10;
                    if (percentage===0 || Number.isNaN(percentage) || !Number.isFinite(percentage)) return '';
                    return percentage.toFixed(1) + "%";
                }
            }
        }
    };

    const TextoEnCentro = {
        id: 'TextoEnCentro',
        afterDraw(chart) {
            const { ctx, chartArea: { left, top, width, height } } = chart;
            ctx.save();
            ctx.textAlign = 'center';
            ctx.font = 'bolder 14px Arial';
            const tot = (chart._metasets[0].total).toFixed(1) + ' ' + data.unidad;
            ctx.fillText(tot, (width / 2) + left, (height / 2) + top);
            ctx.font = 'bolder 16px Arial';
            ctx.fillStyle = 'blue';
            // console.log('TextoEnCentro: (totalAbs): '+totalAbs+ ' - '+ chart._metasets[0].total + ' = ' + (totalAbs - chart._metasets[0].total));
            const percentge = (chart._metasets[0].total * 100 / totalAbs).toFixed(1) + "%";
            ctx.fillText(percentge, (width / 2) + left, (height / 2) + top + 20);
        }
    };

    /*
    // https://www.youtube.com/watch?v=YcRj52VovYQ
    const TextoAlFinalDeLinea = {
        id: 'TextoAlFinalDeLinea',
        afterDraw(chart) {
            const { ctx, chartArea: { left, top, right, bottom, width, height } } = chart;
            console.log(left, top, right, bottom, width, height, ctx);
            chart.data.datasets.forEach((dataset, i) => {
                chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                    const { x, y } = datapoint.tooltipPosition();
                    const mw = width / 2;
                    const mh = height / 2;
                    const xO = (x>=mw)? x+15 : x-15;
                    const yO = (y>=mh)? y+15 : y-15;
                    console.log(index, x, y, mw, mh, xO, yO);

                    ctx.beginPath();
                    ctx.moveTo(x,y);
                    ctx.lineTo(xO, yO);
                    ctx.strokeStyle = dataset.backgroundColor[index];
                    ctx.stroke();
                });
            });
        }
    };
    */

    return (
        <div className="grafica flex justify-content-center">
            <Chart type="doughnut" data={chartData} options={lightOptions} plugins={[ChartDataLabels, TextoEnCentro]} id={idChart} />
        </div>
    );
}
