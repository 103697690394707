//@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { NavLink, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../constants/routes';
import { EMPRESAS_LIST } from '../../../queries/queries_empresa';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';


export default function ListEmpresas() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { loading, error, data } = useQuery(EMPRESAS_LIST, {
        variables: {
            pais: GlobalStore.countryId
        }
    });

    if (GlobalStore.userType > 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const handleTableValue = _.map(data.empresas, e => {
        return {
            ...e,
            publicado: e.publicado ? 'Activo' : 'Despublicado',
            edit: (
                <NavLink to={`${ROUTES.UPDATE_EMPRESA}/${e.id}`}>
                    <i className="pi pi-pencil" style={{ cursor: "pointer" }}></i>
                </NavLink>
            )
        };
    });

    return (
        <React.Fragment>
            <h1>{t('crud.companies_management')}</h1>
            <NavLink to={ROUTES.CREATE_EMPRESA}>
                <Button
                    type="button"
                    label={t('crud.add_company')}
                    className="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="right"
                    style={{ marginBottom: 15 }}
                />
            </NavLink>
            <DataTable value={handleTableValue} responsiveLayout="scroll">
                <Column
                    field="nombreEmpresa"
                    header={t('field.company')}
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                />
                <Column field="publicado" header={t('field.status')} sortable={true} />
                <Column field="edit" header={t('field.edit')} />
            </DataTable>
        </React.Fragment>
    );
}


