////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as ROUTES from '../../../constants/routes';
import { CompLoading } from '../../Loaders';
import { PAIS_DEF, PAIS_ADD, PAISES_LIST } from '../../../queries/queries_pais';
import Err from '../../Err';

import FieldBuilder from '../../../helpers/FieldBuilder';
import validationSchema from '../../../helpers/validationSchema';
import initialValues from '../../../helpers/initialValues';

export default function AddPais() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(PAIS_DEF);

    const [
        addPais,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(PAIS_ADD);

    if (GlobalStore.userType !== 0) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const def = JSON.parse(data.definicion);
    const { uid } = GlobalStore.user;

    return (
        <React.Fragment>
            <div className="breadcrum">
                <Link to={ROUTES.READ_PAISES}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.registered_country')}
                </Link>
            </div>
            <h2>{t('crud.create_country')}</h2>
            {mutationLoading && <CompLoading />}
            <Err mutationError={mutationError} />
            <Formik
                initialValues={initialValues(def)}
                validationSchema={validationSchema(def)}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    await addPais({
                        variables: {
                            ...values,
                            uid
                        },
                        refetchQueries: [
                            {
                                query: PAISES_LIST,
                            },
                        ],
                    });
                    navigate(ROUTES.READ_PAISES);
                }}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="p-inputgroup">
                                <FieldBuilder def={def} />
                            </div>
                            <small>
                                {t('crud.link_to_codes_1')}
                                <a href="https://www.iso.org/obp/ui/es/#search" rel="noopener noreferrer" target="_blank" >
                                    {t('crud.link_to_codes_2')}
                                </a>
                                {t('crud.link_to_codes_3')}
                            </small>
                            <div className="btn-actions">
                                <Button
                                    type="submit"
                                    label={t('common:save')}
                                    icon="pi pi-check"
                                    disabled={mutationLoading}
                                />
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
