//@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import * as ROUTES from '../../constants/routes';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
    FaHome,
    FaUserEdit,
    FaChartBar,
    FaTools,
    FaUsers,
    FaListAlt,
} from 'react-icons/fa';

export default function CompMenu() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    return (
        <ul>
            <li>
                <NavLink title={t('common:home')} to={ROUTES.DASHBOARD}>
                    <FaHome />
                    <span>{t('common:home')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('common:edit_profile')} to={`${ROUTES.UPDATE_USER}/${GlobalStore.user.uid}`}>
                    <FaUserEdit />
                    <span>{t('common:edit_profile')}</span>
                </NavLink>
            </li>

            <li>
                <NavLink title={t('menu.configuration_sheets')} to={ROUTES.READ_CONFIG}>
                    <FaTools />
                    <span>{t('menu.configuration_sheets')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.results')} to={ROUTES.READ_RESULTS}>
                    <FaChartBar />
                    <span>{t('menu.results')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.admins_of_finca')} to={ROUTES.READ_USERS}>
                    <FaUsers />
                    <span>{t('menu.admins_of_finca')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('common:farms')} to={ROUTES.READ_FINCAS}>
                    <FaListAlt />
                    <span>{t('common:farms')}</span>
                </NavLink>
            </li>
        </ul>
    );
}
