//@ts-check

import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { REPORT_HUELLA_CARBONO } from '../../../../queries/queries_results';
import { CompLoading } from '../../../Loaders';

import HuellaCarbonoVariableTabla from './tablas/HuellaCarbonoVariableTabla';
import Err from '../../../Err';

export default function HuellaCarbonoVariables({requestData}) {
    const { t } = useTranslation();

    const { loading, error, data } = useQuery(REPORT_HUELLA_CARBONO, {
        fetchPolicy: 'no-cache',
        variables: { ...requestData }
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const dataParsed = JSON.parse(data.reporteHuellaCarbono);

    return (
        <div className="result-item results-1">
            <h3> {t('domains:fuenteEmision.'+requestData.formulario)} </h3>

            <HuellaCarbonoVariableTabla data={{...dataParsed, formulario: requestData.formulario }} />
        </div>
    );
}
