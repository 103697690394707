import { gql } from '@apollo/client';

const USO_SUELO_CULTIVO_FORESTAL_CALC = gql`
	query calculoUsoSueloCultivoForestal(
		$areaCoberturaIntensiva: Float
		$tipoCoberturaIntensiva: String
		$areaCoberturaExtensiva: Float
		$tipoCoberturaExtensiva: String
		$zonaClimatica: String
		$volumenMaderaIntensiva: Float
		$volumenLenyaIntensiva: Float
		$areaAfectadaIntensiva: Float
		$volumenMaderaExtensiva: Float
		$volumenLenyaExtensiva: Float
		$areaAfectadaExtensiva: Float
	) {
		calculoUsoSueloCultivoForestal(
			areaCoberturaIntensiva: $areaCoberturaIntensiva
			tipoCoberturaIntensiva: $tipoCoberturaIntensiva
			areaCoberturaExtensiva: $areaCoberturaExtensiva
			tipoCoberturaExtensiva: $tipoCoberturaExtensiva
			zonaClimatica: $zonaClimatica
			volumenMaderaIntensiva: $volumenMaderaIntensiva
			volumenLenyaIntensiva: $volumenLenyaIntensiva
			areaAfectadaIntensiva: $areaAfectadaIntensiva
			volumenMaderaExtensiva: $volumenMaderaExtensiva
			volumenLenyaExtensiva: $volumenLenyaExtensiva
			areaAfectadaExtensiva: $areaAfectadaExtensiva
		)
	}
`;

export { USO_SUELO_CULTIVO_FORESTAL_CALC };