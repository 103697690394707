////@ts-check

import React, { useRef } from 'react';
import { Chart } from 'primereact/chart';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { singleRounder } from '../../../../../helpers/rounder';

import './graficas.scss';
import colores from './colores.json';
/**
 * @param {*} param0
 *  data: {
 *      title: string       // Titulo de la gráfica
 *      variable: string    // nombre de la variable a representar
 *      values: [numbers]   // Array de valores
 *  }
 */
export default function ChartColumns({ data, idChart }) {
    let contColor = 10 * Math.random();
    const { t, i18n } = useTranslation();
    // const totalAbs = data.values.reduce((ac, el) => ac + (1 * (Math.abs((el[data.variable] || 0)))), 0);
    const label = t('tables.h_' + data.variable) + ' ( ' + t('tables.h_' + data.variable+'_u') + ' ) ';

    const profitChartRef = useRef();

    if (profitChartRef?.current) {
        // console.log(profitChartRef.current);
        // console.log(profitChartRef.current.getChart());
        // @ts-ignore
        profitChartRef.current.getChart().destroy();
    }



    const randomHSL = (v) => {
        if (colores[v]) {
            const c =  colores[v];
            // console.log('"' + v.fuenteEmision + '": [' + c[0] + ' , ' + c[1] + ' , ' + c[2]  + ' ], ' );
            return 'hsla(' + c[0] + ',' + c[1] +'%,' + c[2] + '%, 1)';
        } else {
            const h = (36 * Math.random()) + (36 * contColor);
            contColor = (contColor + 3) % 10;
            console.log('"' + v + '": [' + (~~(h)) + ' , 80 , 70 ], ' );
            colores[v] = [ ~~(h) , 80 , 70 ];
            return 'hsla(' + ~~(h) + ',' + '80%,' + '70%,1)';
        }
    };

    const traduceFuenteEmision = (f) => {
        if (i18n.exists('chart.'+f)) {
            return t('chart.'+f).split('#');
        }
        if (i18n.exists('menu.'+f)) {
            return t('menu.' + f);
        }
        return f;
    };

    const color = randomHSL(data.variable);
    const chartData = {
        labels: _.map(data.values, (registros) => {
            const f = registros.fuenteEmision || registros.fuente;
            return traduceFuenteEmision(f);
        }),
        datasets: [
            {
                label: label,
                data: _.map(data.values, (el) => singleRounder(el[data.variable])),
                backgroundColor: _.map(data.values, () => color),
                // borderWidth: 1, // Anchura de borde
                // cutout: '80%',  // grosor de la porción
                // borderRadius: 10, // radio de la terminación de la porción
                // offset: 5   // espacio entre porciones
            }
        ]
    };

    const lightOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                font: { weight: 'bold' },
                text: t('chart.' + data.title)
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function(val) { // index
                        // return index % 2 === 0 ? this.getLabelForValue(val) : '';
                        return this.getLabelForValue(val);
                    },
                    color: 'black'
                }
            }
        },
        layout: {
            padding: {
                left: 20,
                right: 20
            }
        }

    };

    return (
        <div className="grafica flex justify-content-center">
            <Chart type="bar" data={chartData} options={lightOptions} ref={profitChartRef} id={idChart} redraw />
        </div>
    );
}
