//import config from "../config/firebase-development"; // Development
//import config from "../config/firebase-production"; //
// import config from "../config/firebase-fao/firebase-development"; //
 import config from "../config/firebase-fao/firebase-production"; //
// import app from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";

import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import { initializeApp } from 'firebase/app';

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
  }
  logout = () => this.auth.signOut();
}

export default Firebase;
