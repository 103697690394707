//@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import _ from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FINCAS_LIST } from '../../../queries/queries_finca';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';


export default function FincasSelector() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(FINCAS_LIST, {
        variables: {
            uid: GlobalStore.user.uid
        }
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const options = _.map(data.fincas, ({ id, nombreFinca }) => {
        return {
            label: nombreFinca,
            value: id
        };
    });

    const { handleChange, handleBlur, values } = GlobalStore.formik;

    const fieldname = 'fincas';

    return (
        <MultiSelect
            name={fieldname}
            id={fieldname}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[fieldname]}
            options={options}
            placeholder={t('common:farms')}
        />
    );
}
