//@ts-check

import React, { useContext } from 'react';
import * as ROUTES from '../../constants/routes';
import { NavLink } from 'react-router-dom';
import { MobXProviderContext } from 'mobx-react';

import { useTranslation } from 'react-i18next';

// import FincaSelectorGlobal from '../Dashboard/UserOp/FincaSelectorGlobal';

import {
    FaHome,
    FaLightbulb,
    FaIndustry,
    FaTractor,
    FaWrench,
    FaSnowflake,
    FaSeedling,
    FaTrashAlt,
    FaTint,
    FaUserEdit,
    FaStickerMule
} from 'react-icons/fa';

import {
    GiTurd,
    GiForest,
    GiCoalPile
} from "react-icons/gi";


export default function OpMenu() {
    const { GlobalStore } = useContext(MobXProviderContext);

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ul>
                <li>
                    <NavLink title={t('common:home')} to={ROUTES.DASHBOARD} end>
                        <FaHome />
                        <span style={{paddingRight:"5px"}}>{t('common:home')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('common:edit_profile')} to={`${ROUTES.UPDATE_USER}/${GlobalStore.user.uid}`}>
                        <FaUserEdit />
                        <span style={{paddingRight:"5px"}}>{t('common:edit_profile')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.electricity')} to={`${ROUTES.READ_FORMS}/electricidad`}>
                        <FaLightbulb />
                        <span style={{paddingRight:"5px"}}>{t('menu.electricity')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.stationary_combustion')} to={`${ROUTES.READ_FORMS}/combustion-estacionaria`}>
                        <FaIndustry />
                        <span style={{paddingRight:"5px"}}>{t('menu.stationary_combustion')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.mobile_combustion')} to={`${ROUTES.READ_FORMS}/combustion-movil`}>
                        <FaTractor />
                        <span style={{paddingRight:"5px"}}>{t('menu.mobile_combustion')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.maintenance_products')} to={`${ROUTES.READ_FORMS}/mantenimiento`}>
                        <FaWrench />
                        <span style={{paddingRight:"5px"}}>{t('menu.maintenance_products')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.refrigeration')} to={`${ROUTES.READ_FORMS}/refrigeracion`}>
                        <FaSnowflake />
                        <span style={{paddingRight:"5px"}}>{t('menu.refrigeration')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.fertilizers')} to={`${ROUTES.READ_FORMS}/fertilizantes`}>
                        <FaSeedling />
                        <span style={{paddingRight:"5px"}}>{t('menu.fertilizers')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.waste')} to={`${ROUTES.READ_FORMS}/residuos`}>
                        <FaTrashAlt />
                        <span style={{paddingRight:"5px"}}>{t('menu.waste')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.water_footprint')} to={`${ROUTES.READ_FORMS}/huella-agua`}>
                        <FaTint />
                        <span style={{paddingRight:"5px"}}>{t('menu.water_footprint')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.traccion_animal_proc_digestivo')} to={`${ROUTES.READ_FORMS}/traccion-animal-proc-digestivo`}>
                        <FaStickerMule />
                        <span style={{paddingRight:"5px"}}>{t('menu.traccion_animal_proc_digestivo')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.traccion_animal_estiercol')} to={`${ROUTES.READ_FORMS}/traccion-animal-estiercol`}>
                        <GiTurd />
                        <span style={{paddingRight:"5px"}}>{t('menu.traccion_animal_estiercol')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.uso_suelo_forestal_forestal')} to={`${ROUTES.READ_FORMS}/uso-suelo-forestal-forestal`}>
                        <GiForest />
                        <span style={{paddingRight:"5px"}}>{t('menu.uso_suelo_forestal_forestal')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.uso_suelo_cultivo_forestal')} to={`${ROUTES.READ_FORMS}/uso-suelo-cultivo-forestal`}>
                        <GiForest />
                        <span style={{paddingRight:"5px"}}>{t('menu.uso_suelo_cultivo_forestal')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.uso_suelo_forestal_cultivo')} to={`${ROUTES.READ_FORMS}/uso-suelo-forestal-cultivo`}>
                        <GiForest />
                        <span style={{paddingRight:"5px"}}>{t('menu.uso_suelo_forestal_cultivo')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink title={t('menu.uso_suelo_carbono')} to={`${ROUTES.READ_FORMS}/uso-suelo-carbono`}>
                        <GiCoalPile />
                        <span style={{paddingRight:"5px"}}>{t('menu.uso_suelo_carbono')}</span>
                    </NavLink>
                </li>
            </ul>
        </React.Fragment>
    );
}
