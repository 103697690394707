////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { CONFIG_ADD, CONF_CURRENT } from '../../../queries/queries_config';
import * as ROUTES from '../../../constants/routes';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

const TXT_REQUIRED = 'field_required';

function ConfigCreate() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(CONF_CURRENT, {
        variables: {
            uid: GlobalStore.user.uid
        }
    });

    const [
        addConfg,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(CONFIG_ADD);

    if (GlobalStore.userType !== 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    if (mutationLoading) {
        return (
            <React.Fragment>
                <h3>{t('crud.creating_configuration')}</h3>
                <CompLoading />
            </React.Fragment>
        );
    }

    const formik = {
        initialValues: {
            anio: ''
        },
        validationSchema: Yup.object({
            anio: Yup.number().required(TXT_REQUIRED)
        }),
    };

    const { uid } = GlobalStore.user;

    function HandleAnioSelect(formik) {
        const idInput = '_' + Math.round(Math.random() * 10000000000);
        let listTakenYears = _.map(data.configuraciones, ({ anio }) =>
            Number(anio)
        );
        if (!listTakenYears.length) {
            listTakenYears = [Number((new Date()).getFullYear())];
        }
        const range = _.rangeRight(Math.min(...listTakenYears) - 3, Math.max(...listTakenYears) + 4);
        const availableYears = _.difference(range, listTakenYears);
        const options = _.map(availableYears, (year) => {
            return {
                label: year,
                value: year
            };
        });
        return (
            <Dropdown
                name='anio'
                inputId={idInput}
                options={options}
                placeholder={t('common:year')}
                value={formik.values.anio}
                onChange={formik.handleChange}
            />
        );
    }

    return (
        <React.Fragment>
            <h3>{t('crud.create_configuration_sheets')}</h3>
            <p>{t('crud.the_year_must_be_unique')}</p>
            <Err mutationError={mutationError} />
            <Formik
                initialValues={formik.initialValues}
                validationSchema={formik.validationSchema}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    const res = await addConfg({
                        variables: {
                            uid,
                            anio: Number(values.anio),
                            pais: GlobalStore.countryId,
                            empresa: GlobalStore.compId
                        },
                    });
                    const { id, anio } = res.data.configuracionAgregar;
                    navigate(`${ROUTES.UPDATE_CONFIG}/${anio}/${id}`);
                }}>
                {(formik) => (
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div style={{ marginBottom: 15 }}>
                            <label>{t('crud.select_the_year')}</label>
                            <div>{HandleAnioSelect(formik)}</div>
                        </div>
                        <div className="btn-actions">
                            <Button type="submit" label={t('common:create')} />
                        </div>
                    </form>
                )}
            </Formik>
        </React.Fragment>
    );
}

export default ConfigCreate;
