import { gql } from '@apollo/client';

const EMPRESA_DEF = gql`
  {
    definicion(tipoDefinicion: "empresaCrear")
  }
`;
const EMPRESA_GET = gql`
  query EmpresaGet($id: ID!) {
    empresa(id: $id) {
      id
      nombreEmpresa
      publicado
      pais {
        id
      }
    }
    definicion(tipoDefinicion: "empresaActualizar")
  }
`;
const EMPRESA_ADD = gql`
  mutation EmpresaAdd($uid: ID!, $nombreEmpresa: String!, $pais: ID!) {
    empresaCrear(uid: $uid, nombreEmpresa: $nombreEmpresa, pais: $pais) {
      id
    }
  }
`;
const EMPRESA_EDIT = gql`
  mutation EditEmpresa(
    $id: ID
    $uid: ID!
    $nombreEmpresa: String!
    $pais: ID!
  ) {
    empresaActualizar(
      id: $id
      uid: $uid
      nombreEmpresa: $nombreEmpresa
      pais: $pais
    ) {
      id
    }
  }
`;

const EMPRESAS_LIST = gql`
  query EmpresasList($pais: ID) {
    empresas(pais: $pais) {
      id
      nombreEmpresa
      publicado
    }
  }
`;

export { EMPRESA_DEF, EMPRESA_GET, EMPRESA_EDIT, EMPRESA_ADD, EMPRESAS_LIST };
