////@ts-check

import React, { useContext, useState, useEffect } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';

import HandleLabel from './HandleLabel';
import Error from './Error';


export default function Number({ field, required, disabled }) {
    const { i18n } = useTranslation();
    const { GlobalStore: { formik }} = useContext(MobXProviderContext);
    const { fieldname, step, min, max, visibleCondicional } = field;
    const { handleChange, isValid, values, touched, errors } = formik; // setFieldValue
    const [o, setO] = useState({ visible: false, valor: null });

    useEffect(() => {
        values[fieldname] = (values[fieldname]==='' || values[fieldname]===undefined ) ? null : values[fieldname]*1;
        setO({ visible: false, valor: values[fieldname] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isComVisible = () => {
        if (visibleCondicional) {
            const keys = Object.keys(visibleCondicional);
            for (let i = 0; i < keys.length; i++) {
                if (visibleCondicional[keys[i]].find( o => values[keys[i]] === o )) {
                    if (!o.visible) {
                        // setFieldValue(fieldname, o.valor);
                        setO({visible: true, valor: null });
                    }
                    return true;
                }
            }
            if (o.visible) {
                setO({visible: false, valor: values[fieldname] });
                // setFieldValue(fieldname, null);
            }
            return false;
        }
        return true;
    };
    let mf = 2;
    if (step) {
        const tn = (step - step.toFixed()).toString().split('-');
        mf = (tn.length>1) ? 1*tn[1] : tn[0].length - 2;
    }
    mf = (mf < 0) ? 0 : mf;

    const handleKey = (e) => {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <>
            {isComVisible() && (
                <div className="elemForm">
                    <label htmlFor={fieldname} className={`required-${required}`}>
                        <HandleLabel field={field} formik={formik}></HandleLabel>
                    </label>
                    <InputNumber
                        step={step}
                        name={fieldname}
                        inputId={fieldname}
                        onValueChange={handleChange}
                        onKeyDown={handleKey}
                        mode="decimal"
                        value={ values[fieldname] }
                        minFractionDigits={mf}
                        maxFractionDigits={mf}
                        showButtons
                        locale={i18n.language.split('-')[0]}
                        min={min}
                        max={max}
                        disabled={disabled}
                    />
                    <Error
                        isValid={isValid}
                        touched={touched[fieldname]}
                        errors={errors[fieldname]}
                    />

                </div>
            ) || ''}
        </>
    );
}
