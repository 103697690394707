//COMMON
export const DASHBOARD = "/";

//CREATE
export const CREATE_USER = "/add/user/";
export const CREATE_PAIS = "/add/pais/";
export const CREATE_CONFIG = "/add/config/";
export const CREATE_CONFIG_CLONE = "/clone/config";
export const CREATE_EMPRESA = "/add/empresa/";
export const CREATE_FINCA = "/add/finca/";
export const CREATE_FORM = "/add/form";

//READ
export const READ_USERS = "/list/users";
export const READ_PAISES = "/list/paises";
export const READ_EMPRESAS = "/list/empresas";
export const READ_FINCAS = "/list/fincas";
export const READ_CONFIG = "/list/config";
export const READ_CONFIG_GLOBAL = "/list/config-global";
export const READ_FORMS = "/list/forms";
export const READ_RESULTS = "/list/results";
export const READ_COUNTRY_RESULTS = "/list/country-results";

//UPDATE
export const UPDATE_USER = "/edit/user";
export const UPDATE_PAIS = "/edit/pais";
export const UPDATE_EMPRESA = "/edit/empresa";
export const UPDATE_FINCA = "/edit/finca";
export const UPDATE_FORM = "/edit/form";

// DELETE
export const DELETE_FORM = "/delete/form";

// DUPLICATE
export const DUPLICATE_FORM = "/duplicate/form";

//COFIG TABLES
export const UPDATE_CONFIG = "/config/edit";
