// //@ts-check

import React, { Component } from 'react';
import { GMap } from 'primereact/gmap';

import { loadGoogleMaps, removeGoogleMaps } from './LoadGoogleMaps';
/* global google */

export default class MapGoogleSetPoint extends Component {

    /**
     * Constructor
     * @param {*} props { optMap, lat, lng, name, onChange, language }
     * @returns
     */
    constructor(props) {
        super(props);

        this.state = {
            googleMapsReady: false,
            // draggableMarker: false,
            selectedPosition: null,
            overlays: null
        };

        this.onMapClick = this.onMapClick.bind(this);
        // this.onOverlayClick = this.onOverlayClick.bind(this);
        // this.handleDragEnd = this.handleDragEnd.bind(this);
        this.onMapReady = this.onMapReady.bind(this);
    }

    componentDidMount() {
        loadGoogleMaps(this.props.language, () => {
            this.setState({ googleMapsReady: true });
        });
    }

    componentWillUnmount() {
        removeGoogleMaps();
    }

    componentDidUpdate(prevProps) {
        if ( (prevProps.lat !== this.props.lat)
            || (prevProps.lng !== this.props.lng)
            || (prevProps.name !== this.props.name) ) {
            this.setState({
                overlays: this.createMark(this.props.lat, this.props.lng, this.props.name)
            });
        }
    }

    onMapClick(event) {
        const pnt = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        this.setState({
            overlays: this.createMark(pnt.lat, pnt.lng, this.props.name)
        });
        this.props.onChange(pnt);
    }

    onMapReady() {
        this.setState({
            overlays: this.createMark(this.props.lat, this.props.lng, this.props.name)
        });
    }

    createMark(latitud, longitud, name) {
        const lat = (Number(latitud) === latitud) ? latitud : 0;
        const lng = (Number(longitud) === longitud) ? longitud : 0;
        return ((lat === 0) && (lng === 0)) ? [] :
            [ new google.maps.Marker({position: { lat: lat, lng: lng }, title: name || ''}) ];
    }

    render() {
        const optionsMap = this.props.optMap || {
            center: {lat: this.props.lat || 0, lng: this.props.lng || 0},
            zoom: 3,
            // draggableCursor: 'default'
            draggableCursor: 'pointer'
        };
        return (
            <div>
                {
                    this.state.googleMapsReady && (
                        <div style={{ marginBottom: 2 }}>
                            <GMap
                                overlays={this.state.overlays}
                                options={optionsMap}
                                style={{ width: '100%', minHeight: '320px' }}
                                onMapReady={this.onMapReady}
                                onMapClick={this.onMapClick}
                            // onOverlayClick={onOverlayClick}
                            // onOverlayDragEnd={handleDragEnd}
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}
