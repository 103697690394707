import { gql } from '@apollo/client';

const USO_SUELO_CARBONO_CALC = gql`
	query calculoUsoSueloCarbono(
		$parcela: String
		$areaIntervencion: Float
		$densidad_10: Float
		$carbono_10: Float
		$densidad_30: Float
		$carbono_30: Float
		$fechaInicio: String
		$finca: ID!
	) {
		calculoUsoSueloCarbono(
			parcela: $parcela
			areaIntervencion: $areaIntervencion
			densidad_10: $densidad_10
			carbono_10: $carbono_10
			densidad_30: $densidad_30
			carbono_30: $carbono_30
			fecha: $fechaInicio
			finca: $finca
		)
	}
`;

export { USO_SUELO_CARBONO_CALC };
