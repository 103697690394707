import { gql } from '@apollo/client';

const FERTILIZANTES_CALC = gql`
  query CalcFertilizantes(
    $tipoFertilizante: String
    $cantidad: Float
    $tipoFE: String
    $cantidadN: Float
    $empresa: ID!
  ) {
    calculoFertilizantes(
      tipoFertilizante: $tipoFertilizante
      cantidad: $cantidad
      tipoFE: $tipoFE
      cantidadN: $cantidadN
      empresa: $empresa
    )
  }
`;

export { FERTILIZANTES_CALC };
