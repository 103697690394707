import { gql } from '@apollo/client';

const USER_DEF = gql`
  {
    definicion(tipoDefinicion: "usuarioCrear")
  }
`;

const USER_GET = gql`
  query UserGet($id: ID!) {
    usuario(id: $id) {
      id
      displayName
      email
      phoneNumber
      type
      pais {
        id
      }
      empresa {
        id
        nombreEmpresa
      }
      fincas {
        id
        nombreFinca
      }
    }
    definicion(tipoDefinicion: "usuarioActualizar")
  }
`;
const USER_ADD = gql`
  mutation UserAdd(
    $uid: ID!
    $email: String!
    $password: String!
    $displayName: String!
    $phoneNumber: String!
    $type: Int!
    $empresa: ID
    $fincas: [ID]
    $pais: ID
  ) {
    usuarioCrear(
      uid: $uid
      email: $email
      password: $password
      displayName: $displayName
      phoneNumber: $phoneNumber
      type: $type
      empresa: $empresa
      fincas: $fincas
      pais: $pais
    ) {
      id
    }
  }
`;

const USER_ENABLE = gql`
  mutation EnableUser($id: ID!, $uid: ID!) {
    usuarioActivar(id: $id, uid: $uid)
  }
`;

const USER_DISABLE = gql`
  mutation DisableUser($id: ID!, $uid: ID!) {
    usuarioDesactivar(id: $id, uid: $uid)
  }
`;

const USER_EDIT = gql`
  mutation EditUser(
    $id: ID!
    $email: String
    $password: String
    $displayName: String
    $phoneNumber: String
    $type: Int
    $empresa: ID
    $fincas: [ID]
    $pais: ID
  ) {
    usuarioActualizar(
      id: $id
      email: $email
      password: $password
      displayName: $displayName
      phoneNumber: $phoneNumber
      type: $type
      empresa: $empresa
      fincas: $fincas
      pais: $pais
    ) {
      id
    }
  }
`;

const USERS_LIST = gql`
  query UserList($type: Int, $pais: ID, $empresa: ID, $finca: ID) {
    usuarios(type: $type, pais: $pais, empresa: $empresa, finca: $finca) {
      id
      displayName
      email
      disabled
      type
      pais {
        nombrePais
      }
    }
  }
`;
export {
  USER_DEF,
  USER_ADD,
  USER_GET,
  USER_EDIT,
  USER_ENABLE,
  USER_DISABLE,
  USERS_LIST,
};
