////@ts-check

import React, { useContext, useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { NavLink, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../constants/routes';
import { CONFIG_LIST, CONFIG_ACTIVATE } from '../../../queries/queries_config';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';


export default function ListConfig() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const { loading: dataLoading, error, data, refetch } = useQuery(CONFIG_LIST, {
        fetchPolicy: 'no-cache',
        variables: {
            uid: GlobalStore.user.uid,
        },
    });

    const [activateConfig, { error: mutationError }] = useMutation(
        CONFIG_ACTIVATE
    );

    if (GlobalStore.userType !== 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (dataLoading || loading) {
        return <CompLoading />;
    }

    if (error || mutationError) {
        return <Err error={error} mutationError={mutationError} />;
    }

    const handleActivateConfig = async (id) => {
        setLoading(true);
        await activateConfig({
            variables: {
                id: GlobalStore.compId,
                uid: GlobalStore.user.uid,
                configuracion: id,
            },
        });
        await refetch();
        setLoading(false);
    };

    const handleTableValue = _.map(data.configuraciones, (c) => {
        return {
            ...c,
            disabled: c.disabled ? 'Desactivo' : 'Activo',
            containEmptyValues: c.containEmptyValues ? (
                <i className="pi pi-times" />
            ) : (
                <i className="pi pi-check" />
            ),
            active: c.configuracionActiva ? (
                <Button
                    type="button"
                    label={t('common:active')}
                    className="p-button-rounded"
                    icon="pi pi-check"
                    iconPos="right"
                    disabled
                />
            ) : (
                <Button
                    type="button"
                    label={t('common:activate')}
                    className="p-button-secondary p-button-rounded"
                    onClick={() => handleActivateConfig(c.id)}
                />
            ),
            edit: (
                <NavLink to={`${ROUTES.UPDATE_CONFIG}/${c.anio}/${c.id}`}>
                    <i className="pi pi-pencil" style={{ cursor: "pointer" }}></i>
                </NavLink>
            ),
            clonar: (
                <NavLink to={`${ROUTES.CREATE_CONFIG_CLONE}/${c.anio}/${c.id}`}>
                    <i className="pi pi-clone" style={{ cursor: "pointer" }}></i>
                </NavLink>
            ),
        };
    });

    return (
        <React.Fragment>
            <h1> {t('menu.configuration_sheets')} </h1>
            <NavLink to={ROUTES.CREATE_CONFIG}>
                <Button
                    label={t('crud.add_configuration_sheet')}
                    className="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="right"
                    style={{ marginBottom: 15 }}
                />
            </NavLink>
            <DataTable value={handleTableValue} responsiveLayout="scroll">
                <Column field="anio" header={t('field.year')} sortable={true} />
                <Column field="containEmptyValues" header={t('field.complete_fields')} />
                <Column field="active" header={t('field.active_configuration')} />
                <Column field="edit" header={t('field.edit')} />
                <Column field="clonar" header={t('field.clone')} />
            </DataTable>
        </React.Fragment>
    );
}
