import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nextHttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { addLocale, locale } from 'primereact/api';

// ------------------------------------------------------------------------------------

window.sacarEntradaDiccionario = (fName, fValue, fText, fcod) => {
    window.allCampos = window.allCampos || {};
    window.campos = window.campos || {};
    window.nomBlq = window.nomBlq || '-';
    if (!window.campos[fName]) {
        console.log('('+ fcod + ') =============== ' + fName );
        window.nomBlq = fName;
        window.campos[fName] = [];
    } else {
        if (window.nomBlq !== fName) {
            window.nomBlq = '-';
            return;
        }
    }
    const newV = fValue
                    .trim()
                    .replace(/\s+/g, '_')
                    .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    .replace(/[ÁÉÍÓÚáéíóúñÑ$%&()?¿¡!’@#+\-.,;:ºª<>/*]*/g, '')
                    .toLowerCase();
    if (!window.allCampos[fName+'_'+newV]) {
        window.campos[fName].push({
            oldFieldValue: fValue,
            newFieldValue: newV,
            fieldText: fText
        });
        window.allCampos[fName+'_'+newV] = true;
        console.log(window.campos);
    }
};

// ------------------------------------------------------------------------------------

i18n
    .use(initReactI18next)
    .use(i18nextHttpBackend)
    .use(LanguageDetector)
    .init({
        supportedLngs: ["en", "es"],
        nonExplicitSupportedLngs: true,
        fallbackLng: "es", // Debe ser el mismo definido en el selector de idioma como "por defecto"
        ns: ['all', 'common', 'domains', 'prime', 'error', 'abstract'],
        defaultNS: 'all',
        backend: {
            loadPath: '/locales/{{ns}}/{{ns}}_{{lng}}.json'
        },
        interpolation: {
            escapeValue: false
        },
        // react: {
        //     useSuspense: false
        // },
        initImmediate: false,
        debug: false,
    }, () => { // err, t
        setTimeout(() => {
            const lg = i18n.language.split('-')[0]; // Firefoz da problemas si no se simplifica el idioma
            addLocale(lg, i18n.getResourceBundle(lg, 'prime'));
            locale(lg);
        });
    });

export default i18n;
