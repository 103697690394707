//@ts-check

import React from "react";
// import il8n from "../../services/i18n.js";
import { useTranslation, withTranslation } from "react-i18next";

import { Dropdown } from "primereact/dropdown";
import { addLocale, locale } from 'primereact/api';

import flag_es from './flags/32/Spain.png';
import flag_en from './flags/32/United-Kingdom.png';

import "./languageSelector.scss";

const LanguageSelector = () => {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const langs = ['en', 'es'];
    const langPorDefecto = 'es'; // Debe ser el mismo definido en el servicio i18next como "por defecto" (fallbackLng)
    const languages = {
        en: { title: 'English', code: 'en', flag: flag_en },
        es: { title: 'Español', code: 'es', flag: flag_es }
    };
    const { i18n: {changeLanguage, getResourceBundle, language }} = useTranslation();
    const langInit =  language.startsWith('en') ? 'en' : (language.startsWith('es') ? 'es' : langPorDefecto);


    const changeLang = (lang) => {
        changeLanguage(lang).then(()=>{
            addLocale(lang, getResourceBundle(lang, 'prime'));
            locale(lang);
        });
    };

    const selectedCountryTemplate = (option) => {
        const opt = languages[option || langInit];
        return (
            <div className="language-item">
                <img alt={opt.title} src={opt.flag} />
            </div>
        );
    };

    const countryOptionTemplate = (option) => {
        const opt = languages[option];
        if (opt) {
            return (
                <div className="language-item">
                    <img alt={opt.title} src={opt.flag} title={opt.title} />
                </div>
            );
        }
        return (<div className="language-item"></div>);
    };

    return (
        <div className="languageSelector">
            <Dropdown
                inputId={idInput}
                onChange={(e) => changeLang(e.target.value)}
                value={langInit}
                options={langs}
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
            />
        </div>
    );
};

export default withTranslation()(LanguageSelector);
