import { gql } from '@apollo/client';

const USO_SUELO_FORESTAL_FORESTAL_CALC = gql`
	query calculoUsoSueloForestalForestal(
		$areaCobertura: Float
		$tipoCobertura: String
		$zonaClimatica: String
		$volumenMadera: Float
		$volumenLenya: Float
		$areaAfectada: Float
	) {
		calculoUsoSueloForestalForestal(
			areaCobertura: $areaCobertura
			tipoCobertura: $tipoCobertura
			zonaClimatica: $zonaClimatica
			volumenMadera: $volumenMadera
			volumenLenya: $volumenLenya
			areaAfectada: $areaAfectada
		)
	}
`;

export { USO_SUELO_FORESTAL_FORESTAL_CALC };