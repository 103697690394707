//@ts-check

import React from "react";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import './fieldBuilder.scss';


export default function HandleLabel({field, formik}) {
    const { t, i18n } = useTranslation();

    const generaTexto = (etiq, txt) => {
        if (i18n.exists('abstract:'+etiq)) {
            return (<span data-title={t('abstract:'+etiq)}> {txt} </span>);
        } else {
            return txt;
        }
    };

    // const getUnitText = (obj) => {
    //     let currentDepValue = formik.values[obj];
    //     console.log(currentDepValue);
    //     return '(----)';
    // };

    if (field.customLabel) {
        const { depFieldname, defaultLabel, options } = field.customLabel;
        for (let i=0; i<depFieldname.length; i++) {
            const visible = !!document.getElementById(depFieldname[i]);
            if (visible) {
                let currentDepValue = formik.values[depFieldname[i]];
                let matcher = _.find(options, ['fieldValue', currentDepValue]);
                if (!_.isEmpty(matcher)) {
                    return t('fields.'+matcher.label);
                }
            }
        }
        return generaTexto(defaultLabel, t('fields.'+defaultLabel)); // t('fields.'+defaultLabel);
    }
    let tx = '';
    if (i18n.exists('fields.'+field.label)) {
        tx = generaTexto(field.label, t('fields.'+field.label)); // t('fields.'+field.label);
    } else {
        tx = generaTexto(field.label, field.label); // field.label;
    }
    return tx; // + ((!field.withUnit) ? '' : getUnitText(field.withUnit));

}
