////@ts-check

import React, { useContext } from 'react';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { observer, MobXProviderContext } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import { Helmet } from 'react-helmet-async';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../../constants/routes';
import { FORM_LIST } from '../../../../queries/queries_forms';
import { CompLoading } from '../../../Loaders';
import mapParamsWithForm from '../../mapParams';
import { numberFormatTxt } from '../../../../helpers/rounder';
import FincaSelectorGlobal from '../../../Dashboard/UserOp/FincaSelectorGlobal';
import Err from '../../../Err';

import './list-forms.scss';

function ListForm() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const params = useParams();
    const { uid } = GlobalStore.user;

    const handleVariables = () => {
        if (GlobalStore.fincaId) {
            return {
                uid,
                tipoFormulario: params.form,
                finca: GlobalStore.fincaId.value,
            };
        }
        return {
            uid,
            tipoFormulario: params.form,
        };
    };

    const { loading, error, data } = useQuery(FORM_LIST, {
        fetchPolicy: 'no-cache',
        variables: handleVariables(),
    });

    if (_.isEmpty(GlobalStore.fincaId)) {
        return (
            <div>
                <h2> {t('crud.please_select_a_Farm')} </h2>
                <p> {t('crud.associate_it_with_a_farm')} </p>
                <FincaSelectorGlobal />
            </div>
        );
    }

    if (GlobalStore.userType !== 3) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const handleTableValue = _.map(data.formularios, (f) => {
        const handleEdit = () => {
            const ownerId = f.creadoPor.id;
            if (ownerId !== uid) {
                return <i className="pi pi-lock" />;
            }
            return (
                <div className="actions">
                    <NavLink to={`${ROUTES.UPDATE_FORM}/${f.tipoFormulario}/${f.id}`}>
                        <i className="pi pi-pencil" style={{ cursor: "pointer" }}></i>
                    </NavLink>
                    <NavLink to={`${ROUTES.DUPLICATE_FORM}/${f.tipoFormulario}/${f.id}`}>
                        <i className="pi pi-copy" style={{ cursor: "pointer" }}></i>
                    </NavLink>
                    <NavLink to={`${ROUTES.DELETE_FORM}/${f.tipoFormulario}/${f.id}`}>
                        <i className="pi pi-trash" style={{ cursor: "pointer" }}></i>
                    </NavLink>
                </div>
            );
        };
        const o = {
                ...f,
                finca: f.finca.nombreFinca,
                creadoPor: f.creadoPor.displayName,
                actualizado: moment(f.fechaActualizacion).format('YYYY/MM/DD h:mm:ss a'),
                annio: moment(f.fechaInputacion).format('YYYY'),
                mes: moment(f.fechaInputacion).format('MM'),
                edit: handleEdit(),
            };
        if (o.alcance && i18n.exists('domains:alcance.' + o.alcance)) {
            o.alcance = t('domains:alcance.' + o.alcance);
        }
        if (o.fuente && i18n.exists('domains:fuente.' + o.fuente)) {
            o.fuente = t('domains:fuente.' + o.fuente);
        }
        if (o.consumo) {
            o.consumo = numberFormatTxt(o.consumo);
        }
        return o;
    });

    const formData = mapParamsWithForm(params.form, GlobalStore);
    const NAME = t('menu.' + formData.NAME);

    const handleProveedor = () => {
        const sinProveedor = ['uso-suelo-forestal-forestal', 'uso-suelo-cultivo-forestal', 'uso-suelo-forestal-cultivo', 'uso-suelo-carbono'];
        if (sinProveedor.indexOf(params.form) !== -1) return null;
        return (
            <Column field="proveedor" header={t('field.provider')} filter={true} filterMatchMode="contains" />
        );
    };

    const handleCentroTrabajo = () => {
        const sinProveedor = ['uso-suelo-forestal-forestal', 'uso-suelo-cultivo-forestal', 'uso-suelo-forestal-cultivo', 'uso-suelo-carbono'];
        if (sinProveedor.indexOf(params.form) !== -1) return null;
        return (
            <Column field="centroTrabajo" header={t('field.workplace')} filter={true} filterMatchMode="contains" />
        );
    };

    const handleConsumo = () => {
        const conConsumo = ['electricidad', 'combustion-estacionaria', 'combustion-movil', 'mantenimiento'];
        if (conConsumo.indexOf(params.form) === -1) return null;
        return (
            <Column field="consumo" header={t('field.consumo')} sortable={true} filterMatchMode="contains" />
        );
    };

    const handleFuente = () => {
        const conFuente = ['combustion-estacionaria', 'combustion-movil', 'mantenimiento', 'refrigeracion', 'fertilizantes'];
        if (conFuente.indexOf(params.form) === -1) return null;
        return (
            <Column field="fuente" header={t('fields.fuente')} sortable={true} filterMatchMode="contains" />
        );
    };

    const handleAlcanceOp = () => {
        if (params.form === 'huella-agua') return null;
        return (
            <Column field="alcance" header={t('field.scope')} sortable={true} filterMatchMode="contains" />
        );
    };

    return (
        <div className="page-forms-list" mdl="src/components/CRUD/Read/Forms/index">
            <Helmet>
                <title>
                    {t('crud.register_of_name', {NAME: NAME})}
                </title>
            </Helmet>
            <div className="breadcrum">
                <Link to={`/`}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.view_record_types')}
                </Link>
            </div>
            <h1>{t('crud.register_of')} {NAME}</h1>
            <NavLink to={`${ROUTES.CREATE_FORM}/${params.form}`}>
                <Button
                    type="button"
                    label={t('common:add_record')}
                    className="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="right"
                    style={{ marginBottom: 15 }}
                />
            </NavLink>
            <DataTable value={handleTableValue} responsiveLayout="scroll" paginator={true} rows={30}>
                <Column field="actualizado" header={t('field.updated')} sortable={true} filterMatchMode="contains" />
                <Column field="annio" header={t('field.year')} sortable={true} />
                <Column field="mes" header={t('field.month')} sortable={true} />
                <Column field="numeroDocumento" header={t('field.num_doc')} sortable={true} />
                <Column field="finca" header={t('field.farm')} filter={true} filterMatchMode="contains" />
                <Column field="creadoPor" header={t('field.created_by')} filter={true} filterMatchMode="contains" />
                {handleConsumo()}
                {handleFuente()}
                {handleAlcanceOp()}
                {handleProveedor()}
                {handleCentroTrabajo()}
                <Column field="edit" header={t('field.edit')} />
            </DataTable>
        </div>
    );
}

export default observer(ListForm);
