import React, {useState} from 'react';
// import _ from 'lodash';
import ChartDoughnutTabla2Wrapper from './ChartDoughnutTabla2Wrapper';

import './graficas.scss';

export default function ChartDoughnutTabla2Contenedor({data, niveles, nivelesList}) {
    const [graficas, setGraficas] = useState(['_GraficaFija_'+ Math.round(Math.random() * 10000000000)]);

    const addChart = () => {
        const idChart = '_' + Math.round(Math.random() * 10000000000);
        setGraficas(oGr => [...oGr, idChart]);
    };

    const delChart = (id) => {
        setGraficas(oGr => oGr.filter(o => o !== id));
    };

    if (data.values && data.values.length === 0) {
        return '';
    }

    return (
        <div className="ContenedorDeGraficas">
            { graficas.map(
                oGr => (oGr===graficas[0])
                    ? <ChartDoughnutTabla2Wrapper data={{...data}} addChart={addChart} idChart={oGr} key={oGr} niveles={niveles} nivelesList={nivelesList}/>
                    : <ChartDoughnutTabla2Wrapper data={{...data}} delChart={delChart} idChart={oGr} key={oGr} niveles={niveles} nivelesList={nivelesList}/>
            )}
        </div>
    );
}
