//@ts-check

import { FORM_DEF } from "../../queries/queries_forms";

import { ELECTRICIDAD_CALC } from "../../queries/queries_electricidad";
import { COMBUSTION_ESTACIONARIA_CALC } from "../../queries/queries_combustion_estacionaria";
import { COMBUSTION_MOVIL_CALC } from "../../queries/queries_combustion_movil";
import { MANTENIMIENTO_CALC } from "../../queries/queries_mantenimiento";
import { REFRIGERACION_CALC } from "../../queries/queries_refrigeracion";
import { FERTILIZANTES_CALC } from "../../queries/queries_fertilizantes";
import { RESIDUOS_CALC } from "../../queries/queries_residuos";
import { HUELLA_AGUA_CALC } from "../../queries/queries_huella_agua";
import { TRACCION_ANIMAL_PROC_DIGESTIVO_CALC } from "../../queries/queries_traccion_animal_Proc_Digestivo";
import { TRACCION_ANIMAL_ESTIERCOL_CALC } from "../../queries/queries_traccion_animal_estiercol";
import { USO_SUELO_FORESTAL_FORESTAL_CALC } from "../../queries/queries_uso_suelo_forestal_forestal";
import { USO_SUELO_CULTIVO_FORESTAL_CALC } from "../../queries/queries_uso_suelo_cultivo_forestal";
import { USO_SUELO_FORESTAL_CULTIVO_CALC } from "../../queries/queries_uso_suelo_forestal_cultivo";
import { USO_SUELO_CARBONO_CALC } from "../../queries/queries_uso_suelo_carbono";

import {
    FaLightbulb,
    FaIndustry,
    FaTractor,
    FaWrench,
    FaSnowflake,
    FaSeedling,
    FaTrashAlt,
    FaTint,
    FaStickerMule // FaTruckLoading, GiCoalPile
} from "react-icons/fa";

import {
    GiTurd,
    GiForest,
    GiCoalPile
} from "react-icons/gi";

export default function MapParamsWithForm(form, store) {
    const QUERY_DEF = FORM_DEF;
    switch (form) {
        case "electricidad":
            return {
                NAME: "electricity", // "Electricidad",
                Ico: FaLightbulb,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioConsumoElectricidad",
                    empresa: store.compId
                },
                QUERY_CALC: ELECTRICIDAD_CALC,
                QUERY_RETURN: "calculoElectricidad",
            };
        case "combustion-estacionaria":
            return {
                NAME: "stationary_combustion", // "Combustión Estacionaria y de Equipos",
                Ico: FaIndustry,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioCombustionEstacionaria",
                    empresa: store.compId
                },
                QUERY_CALC: COMBUSTION_ESTACIONARIA_CALC,
                QUERY_RETURN: "calculoCombustionEstacionaria",
            };
        case "combustion-movil":
            return {
                NAME: "mobile_combustion", // "Combustión Móvil",
                Ico: FaTractor,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioCombustionMovil",
                    empresa: store.compId
                },
                QUERY_CALC: COMBUSTION_MOVIL_CALC,
                QUERY_RETURN: "calculoCombustionMovil",
            };
        case "mantenimiento":
            return {
                NAME: "maintenance_products", // "Productos de Mantenimiento",
                Ico: FaWrench,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioMantenimiento",
                    empresa: store.compId
                },
                QUERY_CALC: MANTENIMIENTO_CALC,
                QUERY_RETURN: "calculoMantenimiento",
            };
        case "refrigeracion":
            return {
                NAME: "refrigeration", // "Refrigeración",
                Ico: FaSnowflake,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioRefrigeracion",
                    empresa: store.compId
                },
                QUERY_CALC: REFRIGERACION_CALC,
                QUERY_RETURN: "calculoRefrigeracion",
            };
        case "fertilizantes":
            return {
                NAME: "fertilizers", // "Fertilizantes y Enmiendas",
                Ico: FaSeedling,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioFertilizantes",
                    empresa: store.compId
                },
                QUERY_CALC: FERTILIZANTES_CALC,
                QUERY_RETURN: "calculoFertilizantes",
            };
        case "residuos":
            return {
                NAME: "waste", // "Residuos",
                Ico: FaTrashAlt,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioResiduos",
                },
                QUERY_CALC: RESIDUOS_CALC,
                QUERY_RETURN: "calculoResiduos",
            };
        case "huella-agua":
            return {
                NAME: "water_footprint", // "Huella de Agua",
                Ico: FaTint,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioHuellaAgua",
                    empresa: store.compId
                },
                QUERY_CALC: HUELLA_AGUA_CALC,
                QUERY_RETURN: "calculoHuellaAgua",
            };
        case "traccion-animal-proc-digestivo":
            return {
                NAME: "traccion_animal_proc_digestivo", // "Traccion animal - Proceso digestivo",
                Ico: FaStickerMule,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioTraccionAnimalProcDigestivo",
                },
                QUERY_CALC: TRACCION_ANIMAL_PROC_DIGESTIVO_CALC,
                QUERY_RETURN: "calculoTraccionAnimalProcDigestivo",
            };
        case "traccion-animal-estiercol":
            return {
                NAME: "traccion_animal_estiercol", // "Traccion animal - Gestión de estiercol",
                Ico: GiTurd,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioTraccionAnimalEstiercol",
                },
                QUERY_CALC: TRACCION_ANIMAL_ESTIERCOL_CALC,
                QUERY_RETURN: "calculoTraccionAnimalEstiercol",
            };
        case "uso-suelo-forestal-forestal":
            return {
                NAME: "uso_suelo_forestal_forestal", // "Uso del Suelo",
                Ico: GiForest,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioUsoSueloForestalForestal",
                },
                QUERY_CALC: USO_SUELO_FORESTAL_FORESTAL_CALC,
                QUERY_RETURN: "calculoUsoSueloForestalForestal",
            };
        case "uso-suelo-cultivo-forestal":
            return {
                NAME: "uso_suelo_cultivo_forestal", // "Uso del Suelo",
                Ico: GiForest,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioUsoSueloCultivoForestal",
                },
                QUERY_CALC: USO_SUELO_CULTIVO_FORESTAL_CALC,
                QUERY_RETURN: "calculoUsoSueloCultivoForestal",
            };
        case "uso-suelo-forestal-cultivo":
            return {
                NAME: "uso_suelo_forestal_cultivo", // "Uso del Suelo",
                Ico: GiForest,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioUsoSueloForestalCultivo",
                },
                QUERY_CALC: USO_SUELO_FORESTAL_CULTIVO_CALC,
                QUERY_RETURN: "calculoUsoSueloForestalCultivo",
            };
        case "uso-suelo-carbono":
            return {
                NAME: "uso_suelo_carbono", // "Uso del Suelo",
                Ico: GiCoalPile,
                QUERY_DEF,
                QUERY_VARIABLES: {
                    tipoDefinicion: "formularioUsoSueloCarbono",
                },
                QUERY_CALC: USO_SUELO_CARBONO_CALC,
                QUERY_RETURN: "calculoUsoSueloCarbono",
            };
        default:
            return null;
    }
}
